import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/docs/docs/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { ListItem, NumberedList, Link } from '@entur/typography';
import './guides.scss';
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const ExpandablePanel = makeShortcode("ExpandablePanel");
const BannerAlertBox = makeShortcode("BannerAlertBox");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "guides"
    }}>{`Guides`}</h1>
    <h2 {...{
      "id": "table-of-contents"
    }}>{`Table of Contents`}</h2>
    <NumberedList className="sales-guides-toc" mdxType="NumberedList">
    <ListItem mdxType="ListItem">
        <Link href="#complete-your-first-sale-with-the-sales-api" mdxType="Link">
            Complete your first sale
        </Link>
        <NumberedList className="sales-guides-toc" mdxType="NumberedList">
            <ListItem mdxType="ListItem">
                <Link href="#get-trip-pattern" mdxType="Link">Get trip pattern</Link>
            </ListItem>
            <ListItem mdxType="ListItem">
                <Link href="#get-offer" mdxType="Link">Get offer</Link>
            </ListItem>
            <ListItem mdxType="ListItem">
                <Link href="#create-order" mdxType="Link">Create order</Link>
            </ListItem>
            <ListItem mdxType="ListItem">
                <Link href="#update-order" mdxType="Link">Update order</Link>
            </ListItem>
            <ListItem mdxType="ListItem">
                <Link href="#reserve-offer" mdxType="Link">Reserve offer</Link>
            </ListItem>
            <ListItem mdxType="ListItem">
                <Link href="#create-payment" mdxType="Link">Create payment</Link>
            </ListItem>
            <ListItem mdxType="ListItem">
                <Link href="#create-a-payment-transaction" mdxType="Link">
                    Create a payment transaction
                </Link>
            </ListItem>
            <ListItem mdxType="ListItem">
                <Link href="#create-terminal" mdxType="Link">Create terminal</Link>
            </ListItem>
            <ListItem mdxType="ListItem">
                <Link href="#capture-transaction" mdxType="Link">Capture transaction</Link>
            </ListItem>
            <ListItem mdxType="ListItem">
                <Link href="#generate-and-distribute-ticket-distributions" mdxType="Link">
                    Generate and distribute ticket distributions
                </Link>
                <NumberedList className="sales-guides-toc" mdxType="NumberedList">
                    <ListItem mdxType="ListItem">
                        <Link href="#pdf-tickets" mdxType="Link">
                            Generate and send PDF-tickets
                        </Link>
                    </ListItem>
                </NumberedList>
            </ListItem>
            <ListItem mdxType="ListItem">
                <Link href="#custom-entur-properties-in-nod" mdxType="Link">
                    Custom Entur properties in NOD
                </Link>
            </ListItem>
        </NumberedList>
    </ListItem>
    <ListItem mdxType="ListItem">
        <Link href="#searching-for-orders" mdxType="Link">Searching for Orders</Link>
    </ListItem>
    <ListItem mdxType="ListItem">
        <Link href="#refunding" mdxType="Link">Refunding</Link>
        <NumberedList className="sales-guides-toc" mdxType="NumberedList">
            <ListItem mdxType="ListItem">
                <Link href="#get-refund-options" mdxType="Link">Get refund options</Link>
            </ListItem>
            <ListItem mdxType="ListItem">
                <Link href="#refund-order-line" mdxType="Link">Refund order line</Link>
            </ListItem>
        </NumberedList>
    </ListItem>
    <ListItem mdxType="ListItem">
        <Link href="#manual-refunding" mdxType="Link">Manual refunding</Link>
    </ListItem>
    <ListItem mdxType="ListItem">
        <Link href="#cancellation" mdxType="Link">Cancellation</Link>
    </ListItem>
    <ListItem mdxType="ListItem">
        <Link href="#concurrent-order-changes" mdxType="Link">Concurrent order changes</Link>
        <NumberedList className="sales-guides-toc" mdxType="NumberedList">
            <ListItem mdxType="ListItem">
                <Link href="#updating-a-locked-order" mdxType="Link">Updating a locked order</Link>
            </ListItem>
            <ListItem mdxType="ListItem">
                <Link href="#order-locking-overview" mdxType="Link">Order Locking Overview</Link>
            </ListItem>
        </NumberedList>
    </ListItem>
    <ListItem mdxType="ListItem">
        <Link href="#period-ticket" mdxType="Link">Period ticket</Link>
    </ListItem>
    <ListItem mdxType="ListItem">
        <Link href="#zero-ticket" mdxType="Link">Zero ticket</Link>
    </ListItem>
    <ListItem mdxType="ListItem">
        <Link href="#payment-methods" mdxType="Link">Payment methods</Link>
        <NumberedList className="sales-guides-toc" mdxType="NumberedList">
            <ListItem mdxType="ListItem">
                <Link href="#vipps" mdxType="Link">Pay with Vipps</Link>
            </ListItem>
            <ListItem mdxType="ListItem">
                <Link href="#paypal" mdxType="Link">Pay with PayPal</Link>
            </ListItem>
            <ListItem mdxType="ListItem">
                <Link href="#gift-card" mdxType="Link">Pay with gift card</Link>
            </ListItem>
            <ListItem mdxType="ListItem">
                <Link href="#invoice" mdxType="Link">Pay with invoice</Link>
            </ListItem>
            <ListItem mdxType="ListItem">
                <Link href="#imported-payment-methods" mdxType="Link">Imported payments</Link>
            </ListItem>
            <ListItem mdxType="ListItem">
                <Link href="#fees" mdxType="Link">Fees</Link>
            </ListItem>
        </NumberedList>
    </ListItem>
    <ListItem mdxType="ListItem">
        <Link href="#accessing-the-external-kafka-cluster" mdxType="Link">
            Accessing the external Kafka Cluster
        </Link>
        <NumberedList className="sales-guides-toc" mdxType="NumberedList">
            <ListItem mdxType="ListItem">
                <Link href="#prerequisites" mdxType="Link">Prerequisites</Link>
            </ListItem>
            <ListItem mdxType="ListItem">
                <Link href="#quickstart" mdxType="Link">Quickstart</Link>
            </ListItem>
            <ListItem mdxType="ListItem">
                <Link href="#security" mdxType="Link">Security</Link>
            </ListItem>
            <ListItem mdxType="ListItem">
                <Link href="#testing-client-configuration" mdxType="Link">
                    Testing Client Configuration
                </Link>
            </ListItem>
            <ListItem mdxType="ListItem">
                <Link href="#topic-descriptions" mdxType="Link">Topic Descriptions</Link>
            </ListItem>
            <ListItem mdxType="ListItem">
                <Link href="#naming-conventions" mdxType="Link">Naming conventions</Link>
            </ListItem>
            <ListItem mdxType="ListItem">
                <Link href="#topics" mdxType="Link">Topics</Link>
            </ListItem>
            <ListItem mdxType="ListItem">
                <Link href="#schema-registry" mdxType="Link">Schema Registry</Link>
            </ListItem>
        </NumberedList>
    </ListItem>
    <ListItem mdxType="ListItem">
        <Link href="#add-recurring-payment" mdxType="Link">Add Recurring Payment</Link>
        <NumberedList className="sales-guides-toc" mdxType="NumberedList">
            <ListItem mdxType="ListItem">
                <Link href="#create-recurring-payment" mdxType="Link">
                    Create Recurring Payment
                </Link>
            </ListItem>
            <ListItem mdxType="ListItem">
                <Link href="#create-terminal" mdxType="Link">Create Terminal</Link>
            </ListItem>
            <ListItem mdxType="ListItem">
                <Link href="#authorize-recurring-terminal" mdxType="Link">
                    Authorize Recurring Terminal
                </Link>
            </ListItem>
        </NumberedList>
    </ListItem>
    <ListItem mdxType="ListItem">
        <Link href="#use-recurring-payments" mdxType="Link">Usage of Recurring Payments</Link>
        <NumberedList className="sales-guides-toc" mdxType="NumberedList">
            <ListItem mdxType="ListItem">
                <Link href="#get-recurring-payments-for-customer" mdxType="Link">
                    Get recurring payments for customer
                </Link>
            </ListItem>
        </NumberedList>
    </ListItem>
    <ListItem mdxType="ListItem">
        <Link href="#strong-customer-authentication-exemption" mdxType="Link">
            Strong Customer Authentication Exemption
        </Link>
    </ListItem>
    <ListItem mdxType="ListItem">
        <Link href="#error-handling" mdxType="Link">Error handling</Link>
        <NumberedList className="sales-guides-toc" mdxType="NumberedList">
            <ListItem mdxType="ListItem">
                <Link href="#payment" mdxType="Link">Payment</Link>
            </ListItem>
        </NumberedList>
    </ListItem>
    </NumberedList>
    <h2 {...{
      "id": "complete-your-first-sale-with-the-sales-api"
    }}>{`Complete your first sale with the sales API`}</h2>
    <p><a parentName="p" {...{
        "href": "#table-of-contents"
      }}>{`(back to table of contents)`}</a></p>
    <p>{`This guide shows how to create an order, populate it with an offer, pay for the order, and distribute tickets. Each code step contains a request body (if needed) and the response. If the request body is missing then the request can or should be executed without a body. It is recommended that beginners of the API try this example first.`}</p>
    <h3 {...{
      "id": "get-trip-pattern"
    }}>{`Get trip pattern`}</h3>
    <p><a parentName="p" {...{
        "href": "#table-of-contents"
      }}>{`(back to table of contents)`}</a></p>
    <Link href="/pages-journeyplanner-journeyplanner" mdxType="Link">Journey Planner API</Link> is used to perform a travel search and get a trip pattern representing the trip.<br /><br />
    <ExpandablePanel title="Example trip search: Oslo S (NSR:StopPlace:337) - Drammen (NSR:StopPlace:11)" mdxType="ExpandablePanel">
    <pre>{`
{
  trip(
    from: {
      place: "NSR:StopPlace:337"
    },
    to: {
      place: "NSR:StopPlace:11"
    },
    modes: {
      transportModes: [{
        transportMode: rail
      }]
    }
    numTripPatterns: 1
  ) {
    tripPatterns {
      legs {
        datedServiceJourney {
          operatingDay
          serviceJourney {
            id
          }
        }
        fromPlace {
          quay {
            stopPlace {
              name
              id
            }
          }
        }
        toPlace {
          quay {
            stopPlace {
              id
            }
          }
        }
      }
    }
  }
}`}</pre>
    </ExpandablePanel>
    <ExpandablePanel title="Example trip search response: Oslo S (NSR:StopPlace:337) - Drammen (NSR:StopPlace:11)" mdxType="ExpandablePanel">
    <pre>{`
{
  "data": {
    "trip": {
      "tripPatterns": [
        {
          "legs": [
            {
              "datedServiceJourney": {
                "operatingDay": "2024-04-23",
                "serviceJourney": {
                  "id": "NSB:ServiceJourney:855-LOD_186471-R"
                }
              },
              "fromPlace": {
                "quay": {
                  "stopPlace": {
                    "name": "Oslo S",
                    "id": "NSR:StopPlace:337"
                  }
                }
              },
              "toPlace": {
                "quay": {
                  "stopPlace": {
                    "id": "NSR:StopPlace:11"
                  }
                }
              }
            }
          ]
        }
      ]
    }
  }
}`}</pre>
    </ExpandablePanel>
    <h3 {...{
      "id": "get-offer"
    }}>{`Get offer`}</h3>
    <p><a parentName="p" {...{
        "href": "#table-of-contents"
      }}>{`(back to table of contents)`}</a></p>
    <p><inlineCode parentName="p">{`POST /offers/v2/search/trip-pattern`}</inlineCode><br />{`
`}<a parentName="p" {...{
        "href": "https://petstore.swagger.io/?url=https://api.entur.io/api-docs/offers"
      }}>{`See the API reference on the Swagger Petstore`}</a></p>
    <p>{`Get an offer for a trip by constructing leg(s) representation as input to the Offer Search API, from the trip pattern response from the Journey Planner API.`}</p>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`travelDate`}</inlineCode>{` is the operatingDay of the DatedServiceJourney representing the specific trip`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`fromStopPlaceId`}</inlineCode>{` is the id of the `}<inlineCode parentName="li">{`StopPlace`}</inlineCode>{` where the leg starts`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`toStopPlaceId`}</inlineCode>{` is the id of the `}<inlineCode parentName="li">{`StopPlace`}</inlineCode>{` where the leg ends`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`serviceJourneyId`}</inlineCode>{` is the id of the `}<inlineCode parentName="li">{`ServiceJourney`}</inlineCode>{` that in combination with `}<inlineCode parentName="li">{`travelDate`}</inlineCode>{` represents the specific trip`}</li>
    </ul>
    <p><inlineCode parentName="p">{`travellers`}</inlineCode>{` represent who are performing the trip. In this example, we have one adult.`}</p>
    <ExpandablePanel title="Example Offers search request: Oslo S (NSR:StopPlace:337) - Drammen (NSR:StopPlace:11)" mdxType="ExpandablePanel">
    <pre>{`
{
  "tripPattern": {
    "legs": [
      {
        "travelDate": "2024-04-23",
        "fromStopPlaceId": "NSR:StopPlace:337",
        "toStopPlaceId": "NSR:StopPlace:11",
        "serviceJourneyId": "NSB:ServiceJourney:855-LOD_186471-R"
      }
    ]
  },
  "travellers": [
    {
      "userType": "ADULT"
    }
  ]
}
}`}</pre>
    </ExpandablePanel>
    <ExpandablePanel title="Example Offers search response: Oslo S (NSR:StopPlace:337) - Drammen (NSR:StopPlace:11)" mdxType="ExpandablePanel">
    <pre>{`
{
    "tripPatternId": "1481044e-8818-4290-8e02-cefd752aedc5",
    "offers": [
        {
            "id": "6b2f89c0-f36a-474c-9661-6c40fa730d6e",
            "name": "Enkeltbillett",
            "description": "Enkeltbillett",
            "price": {
                "amount": "139.00",
                "currency": "NOK"
            },
            "availableFulfillmentMethods": [
                {
                    "id": "ENT:FulfilmentMethod:FysiskBillett",
                    "version": "ENT:Version:FM-FysiskBillett-1"
                }
            ],
            "geographicalValidity": {
                "serviceJourneys": [
                    {
                        "datedServiceJourneyId": "NSB:DatedServiceJourney:855_OSL-SKN_24-04-23",
                        "serviceJourneyId": "NSB:ServiceJourney:855-LOD_186471-R"
                    }
                ],
                "pointToPointValidity": {
                    "fromPlace": "NSR:StopPlace:337",
                    "toPlace": "NSR:StopPlace:11"
                }
            },
            "preassignedProducts": [
                {
                    "id": "VYG:PreassignedFareProduct:61fa0163",
                    "version": "VYG:Version:FP-01c4280c-edd0-44f0-8e5e-816ed4b15f78",
                    "name": "Enkeltbillett",
                    "description": "- Kan endres og refunderes\\n- Ingen bestemt plass\\n- [Mer info hos Vy](https://www.vy.no/kjop-billetter/togbilletter)",
                    "properties": {
                        "isExchangeable": true,
                        "isRefundable": true,
                        "accommodations": [
                            {
                                "serviceJourney": {
                                    "datedServiceJourneyId": "NSB:DatedServiceJourney:855_OSL-SKN_24-04-23",
                                    "serviceJourneyId": "NSB:ServiceJourney:855-LOD_186471-R"
                                },
                                "accommodation": []
                            }
                        ],
                        "reservingRequirements": [
                            {
                                "serviceJourney": {
                                    "datedServiceJourneyId": "NSB:DatedServiceJourney:855_OSL-SKN_24-04-23",
                                    "serviceJourneyId": "NSB:ServiceJourney:855-LOD_186471-R"
                                },
                                "reservingRequirement": "NO_RESERVATIONS_POSSIBLE",
                                "seatMapIsAvailable": false
                            }
                        ],
                        "userProfileSummary": {
                            "id": "VYG:UserProfile:865d10e5",
                            "version": "VYG:Version:UP-b38dd4de-f458-46b4-99d1-9be303e92879",
                            "name": "Voksen",
                            "userType": "ADULT"
                        },
                        "organisation": {
                            "id": "VYG:Authority:VYG",
                            "name": "Vy-gruppen"
                        },
                        "fareClasses": [
                            "STANDARD_CLASS"
                        ],
                        "durationType": "SINGLE_TRIP"
                    },
                    "geographicalValidity": {
                        "serviceJourneys": [
                            {
                                "datedServiceJourneyId": "NSB:DatedServiceJourney:855_OSL-SKN_24-04-23",
                                "serviceJourneyId": "NSB:ServiceJourney:855-LOD_186471-R"
                            }
                        ],
                        "pointToPointValidity": {
                            "fromPlace": "NSR:StopPlace:337",
                            "toPlace": "NSR:StopPlace:11"
                        }
                    }
                }
            ],
            "optionalProductsSelectableIds": [
                "3NBKVH"
            ],
            "travellerMapping": [
                {
                    "travellerIds": [
                        "a3883296-58c1-42f8-8a28-de8fcbe7d299"
                    ],
                    "maxNumberOfTravellers": 1,
                    "minNumberOfTravellers": 1,
                    "userType": "ADULT"
                }
            ],
            "available": 431
        }
    ],
    "optionalProducts": [
        {
            "id": "VYG:SupplementProduct:58501a68",
            "version": "VYG:Version:FP-97adb544-a20a-4cef-8b95-670c248ed83d",
            "selectableId": "3NBKVH",
            "name": "Reserver sete",
            "description": "Reserver sete",
            "price": {
                "amount": "65.00",
                "currency": "NOK"
            },
            "properties": {
                "isExchangeable": false,
                "isRefundable": false,
                "accommodations": [
                    {
                        "serviceJourney": {
                            "datedServiceJourneyId": "NSB:DatedServiceJourney:855_OSL-SKN_24-04-23",
                            "serviceJourneyId": "NSB:ServiceJourney:855-LOD_186471-R"
                        },
                        "accommodation": [
                            "SEATING"
                        ]
                    }
                ],
                "reservingRequirements": [
                    {
                        "serviceJourney": {
                            "datedServiceJourneyId": "NSB:DatedServiceJourney:855_OSL-SKN_24-04-23",
                            "serviceJourneyId": "NSB:ServiceJourney:855-LOD_186471-R"
                        },
                        "reservingRequirement": "RESERVATIONS_COMPULSORY",
                        "seatMapIsAvailable": true
                    }
                ],
                "organisation": {
                    "id": "VYG:Authority:VYG",
                    "name": "Vy-gruppen"
                },
                "fareClasses": [
                    "STANDARD_CLASS"
                ]
            },
            "geographicalValidity": {
                "serviceJourneys": [
                    {
                        "datedServiceJourneyId": "NSB:DatedServiceJourney:855_OSL-SKN_24-04-23",
                        "serviceJourneyId": "NSB:ServiceJourney:855-LOD_186471-R"
                    }
                ],
                "pointToPointValidity": {
                    "fromPlace": "NSR:StopPlace:337",
                    "toPlace": "NSR:StopPlace:11"
                }
            },
            "available": 34,
            "supplementProductType": "SEAT_RESERVATION"
        }
    ]
}`}</pre>
    </ExpandablePanel>
    <h3 {...{
      "id": "create-order"
    }}>{`Create Order`}</h3>
    <p><a parentName="p" {...{
        "href": "#table-of-contents"
      }}>{`(back to table of contents)`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://petstore.swagger.io/?url=https://api.entur.io/api-docs/order?group%3dpublic#/Order%20API/createOrder"
      }}>{`POST /sales/v1/orders`}</a></p>
    <p>{`Create an order to which you want to add offers to. This is done by sending a post request with an empty body.
An order will reset after 20 minutes if no further changes are made, or if it is not confirmed.`}</p>
    <ExpandablePanel title="Example response" mdxType="ExpandablePanel">
    <pre>{`{
    "id": "K2131857",
    "version": 1,
    "status": "DRAFT",
    "totalAmount": "0.00",
    "totalTaxAmount": "0.00",
    "balance": "0.00",
    "currency": "NOK",
    "orderLines": [],
    "paymentRefs": [],
    "travellers": [],
    "reservations": [],
    "organisationId": 1,
    "createdAt": "2019-01-28T09:10:46Z"
}`}</pre>
    </ExpandablePanel>
    <h3 {...{
      "id": "update-order"
    }}>{`Update Order`}</h3>
    <p><a parentName="p" {...{
        "href": "#table-of-contents"
      }}>{`(back to table of contents)`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://petstore.swagger.io/?url=https://api.entur.io/api-docs/order?group%3dpublic#/Order%20API/createOrder"
      }}>{`PUT /sales/v1/orders`}</a></p>
    <p>{`This endpoint updates an existing order with the provided details. Additionally, when the order is updated, the reset time is automatically extended by 20 minutes from the time of the update.`}</p>
    <ExpandablePanel title="Example request" mdxType="ExpandablePanel">
    <pre>{`{
      "contactInfo": {
        "createdBy": {
          "id": "12345"
        },
        "contact": {
          "id": "12345"
        }
      },
      "externalReference": "string",
      "expireAt": "2024-08-19T07:43:02.329Z",
      "specialOrderCause": {
        "causeCode": "string",
        "organisationId": "string"
      }
}`}</pre>
    </ExpandablePanel>
    <ExpandablePanel title="Example response" mdxType="ExpandablePanel">
    <pre>{`{
      "id": "3N7URR0X",
      "version": 2,
      "status": "CONFIRMED",
      "totalAmount": "0.00",
      "balance": "0.00",
      "totalTaxAmount": "0.00",
      "currency": "NOK",
      "orderLines": [],
      "paymentRefs": [],
      "reservations": [],
      "travellers": [],
      "organisationId": 0,
      "createdAt": "2024-08-19T07:43:02.332Z",
      "lastConfirmedAt": "2024-08-19T07:43:02.332Z",
      "fees": [],
      "pos": "string",
      "distributionChannelId": "string",
      "contactInfo": {
        "createdBy": {
          "id": "12345"
        },
        "contact": {
          "id": "12345"
        }
      },
      "externalReference": "string",
      "expireAt": "2024-08-19T07:43:02.332Z",
      "specialOrderCause": {
        "causeCode": "string",
        "organisationId": "string"
      }
}`}</pre>
    </ExpandablePanel>
    <h3 {...{
      "id": "reserve-offer"
    }}>{`Reserve offer`}</h3>
    <p><a parentName="p" {...{
        "href": "#table-of-contents"
      }}>{`(back to table of contents)`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://petstore.swagger.io/?url=https://api.entur.io/api-docs/reserve-offer#/Reserve%20Offer%20API/reserveOffers"
      }}>{`POST /sales/v1/reserve-offers`}</a></p>
    <p>{`Reserve the chosen offer and add it to the specified order`}</p>
    <ExpandablePanel title="Example request" mdxType="ExpandablePanel">
    <pre>{`{
  "orderId": "K2131857",
  "offerConfigurations": [{
    "offerId": "6b2f89c0-f36a-474c-9661-6c40fa730d6e",
    "count": 1,
    "selectableProductIds": ["3NBKVH"]
  }]
}`}</pre>
    </ExpandablePanel>
    <ExpandablePanel title="Example response" mdxType="ExpandablePanel">
    <pre>{`{
    "orderLineIds": [
        "998d1193-e775-457f-b3b4-2435356cff09"
    ],
    "reservationIds": [
        "b12c8731-d3c4-4969-a8d6-e62f732a46c5"
    ]
}`}</pre>
    </ExpandablePanel>
    <h3 {...{
      "id": "create-payment"
    }}>{`Create Payment`}</h3>
    <p><a parentName="p" {...{
        "href": "#table-of-contents"
      }}>{`(back to table of contents)`}</a></p>
    <p>{`Create a payment to the order.`}</p>
    <p><a parentName="p" {...{
        "href": "https://petstore.swagger.io/?url=https://api.entur.io/api-docs/payment%3Fgroup%3Dpublic#/Payment%20API%20Public/createPayment"
      }}>{`POST /sales/v1/payments`}</a></p>
    <ExpandablePanel title="Example request" mdxType="ExpandablePanel">
    <pre>{`{
  "totalAmount": "1064.00",
  "orderId": "K2131857",
  "orderVersion": 1,
  "currency": "NOK"
}`}</pre>
    </ExpandablePanel>
    <ExpandablePanel title="Example response" mdxType="ExpandablePanel">
    <pre>{`{
    "paymentId": 13417,
    "totalAmount": "1064.00",
    "currency": "NOK",
    "orderId": "K2131857",
    "orderVersion": 1,
    "organisationId": 1,
    "transactionHistory": [],
    "createdAt": "2019-01-28T09:26:43Z",
    "_links": {
        "addTransaction": {
            "href": "https://dev.devstage.entur.io/sales/v1/payments/13417/transactions"
        },
        "self": {
            "href": "https://dev.devstage.entur.io/sales/v1/payments/13417"
        }
    }
}`}</pre>
    </ExpandablePanel>
    <p>{`It is also possible to add a transaction to the payment in this call, by adding a transaction request to the payment request.`}</p>
    <ExpandablePanel title="Example request" mdxType="ExpandablePanel">
    <pre>{`{
  "totalAmount": "1064.00",
  "orderId": "K2131857",
  "orderVersion": 1,
  "currency": "NOK",
  "transaction": {
    "amount": "1064.00",
    "currency": "NOK",
    "paymentType": "VISA"
  }
}`}</pre>
    </ExpandablePanel>
    <ExpandablePanel title="Example response" mdxType="ExpandablePanel">
    <pre>{`{
    "paymentId": 13417,
    "totalAmount": "1064.00",
    "currency": "NOK",
    "orderId": "K2131857",
    "orderVersion": 1,
    "organisationId": 1,
    "transactionHistory": [{
        "transactionId": 12733,
        "rrn": "000000012733",
        "currency": "NOK",
        "paymentType": "VISA",
        "paymentTypeGroup": "PAYMENTCARD",
        "summary": {
            "remainingAmountToCapture": "1064.00",
            "capturedAmount": "0.00",
            "remainingAmountToCredit": "0.00",
            "creditedAmount": "0.00"
        },
        "operationLog": [
            {
                "timestamp": "2019-01-28T09:43:44Z",
                "operation": "CREATE",
                "amount": "1064.00",
                "pos": "postman"
            }
        ],
        "amount": "1064.00",
        "status": "CREATED",
        "createdAt": "2019-01-28T09:43:44Z"
    }],
    "createdAt": "2019-01-28T09:26:43Z",
    "_links": {
        "terminal": {
            "href": "https://dev.devstage.entur.io/v1/payments/13421/transactions/12733/terminal"
        },
        "self": {
            "href": "https://dev.devstage.entur.io/sales/v1/payments/13417",
            "href": "https://dev.devstage.entur.io/sales/v1/payments/13421/transactions/12733"
            }
        }
    }`}</pre>
    </ExpandablePanel>
    <h3 {...{
      "id": "create-a-payment-transaction"
    }}>{`Create a Payment Transaction`}</h3>
    <p><a parentName="p" {...{
        "href": "#table-of-contents"
      }}>{`(back to table of contents)`}</a></p>
    <p>{`Create a payment transaction to the specified payment`}</p>
    <p><a parentName="p" {...{
        "href": "https://petstore.swagger.io/?url=https://api.entur.io/api-docs/payment%3Fgroup%3Dpublic#/Payment%20API%20Public/addTransaction"
      }}>{`POST /sales/v1/payments/{paymentId}/transactions`}</a></p>
    <ExpandablePanel title="Example request" mdxType="ExpandablePanel">
    <pre>{`{
  "amount": "1064.00",
  "currency": "NOK",
  "paymentType": "VISA"
}`}</pre>
    </ExpandablePanel>
    <ExpandablePanel title="Example response" mdxType="ExpandablePanel">
    <pre>{`{
    "transactionId": 12733,
    "rrn": "000000012733",
    "currency": "NOK",
    "paymentType": "VISA",
    "paymentTypeGroup": "PAYMENTCARD",
    "summary": {
        "remainingAmountToCapture": "1064.00",
        "capturedAmount": "0.00",
        "remainingAmountToCredit": "0.00",
        "creditedAmount": "0.00"
    },
    "operationLog": [
        {
            "timestamp": "2019-01-28T09:43:44Z",
            "operation": "CREATE",
            "amount": "1064.00",
            "pos": "postman"
        }
    ],
    "amount": "1064.00",
    "status": "CREATED",
    "createdAt": "2019-01-28T09:43:44Z",
    "_links": {
        "terminal": {
            "href": "https://dev.devstage.entur.io/v1/payments/13421/transactions/12733/terminal"
        },
        "self": {
            "href": "https://dev.devstage.entur.io/sales/v1/payments/13421/transactions/12733"
        }
    }
}`}</pre>
    </ExpandablePanel>
    <h3 {...{
      "id": "create-terminal"
    }}>{`Create Terminal`}</h3>
    <p><a parentName="p" {...{
        "href": "#table-of-contents"
      }}>{`(back to table of contents)`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://petstore.swagger.io/?url=https://api.entur.io/api-docs/payment%3Fgroup%3Dpublic#/Payment%20API%20Public/createPaymentTerminal"
      }}>{`POST /sales/v1/payments/{paymentId}/transactions/{transactionId}/terminal`}</a></p>
    <p>{`Create a terminal to execute the transaction. Use the paymentId and the transactionId from the previous requests.
It is possible to avoid the `}<a parentName="p" {...{
        "href": "#capture-transaction"
      }}>{`capture call`}</a>{` by setting `}<inlineCode parentName="p">{`autoSale: true`}</inlineCode>{` in the terminal request.
To be able to use the autoSale feature, callback must be activated for your merchant. You can request that Entur enables callback for your merchant.
The default value for autoSale is false.`}</p>
    <ExpandablePanel title="Example request" mdxType="ExpandablePanel">
    <pre>{`{
    "redirectUrl": "http://entur.org",
    "terminalLanguage": "no_NO",
    "autoSale: true
}`}</pre>
    </ExpandablePanel>
    <ExpandablePanel title="Example response" mdxType="ExpandablePanel">
    <pre>{`
{
    "paymentId": 13421,
    "transactionId": 12733,
    "terminalUri": "https://test.epayment.nets.eu/Terminal/default.aspx?merchantId=12002713&transactionId=c4094db824a246f386bb054de57e5f68",
    "_links": {
        "transaction": {
            "href": "https://dev.devstage.entur.io/v1/payments/13421/transactions/12733"
        }
    }
}`}</pre>
    </ExpandablePanel>
    <h3 {...{
      "id": "capture-transaction"
    }}>{`Capture Transaction`}</h3>
    <p><a parentName="p" {...{
        "href": "#table-of-contents"
      }}>{`(back to table of contents)`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://petstore.swagger.io/?url=https://api.entur.io/api-docs/payment%3Fgroup%3Dpublic#/Payment%20API%20Public/captureTransaction"
      }}>{`PUT /sales/v1/payments/{paymentId}/transactions/{transactionId}/capture`}</a></p>
    <p>{`When the transaction is executed successfully we need to mark the transaction as completed. Use the paymentId and transactionId from the previous requests.`}</p>
    <ExpandablePanel title="Example response" mdxType="ExpandablePanel">
    <pre>{`{
    "paymentId": 13422,
    "totalAmount": "1064.00",
    "currency": "NOK",
    "orderId": "K2131857",
    "orderVersion": 1,
    "organisationId": 1,
    "transactionHistory": [
        {
            "transactionId": 12735,
            "rrn": "000000012735",
            "currency": "NOK",
            "paymentType": "VISA",
            "paymentTypeGroup": "PAYMENTCARD",
            "cardNumber": "0004",
            "summary": {
                "remainingAmountToCapture": "0.00",
                "capturedAmount": "1064.00",
                "remainingAmountToCredit": "1064.00",
                "creditedAmount": "0.00"
            },
            "operationLog": [
                {
                    "timestamp": "2019-01-28T10:04:09Z",
                    "operation": "CAPTURE",
                    "amount": "1064.00",
                    "pos": "postman"
                },
                {
                    "timestamp": "2019-01-28T10:03:31Z",
                    "operation": "INITIATE",
                    "amount": "1064.00",
                    "pos": "postman"
                },
                {
                    "timestamp": "2019-01-28T10:03:28Z",
                    "operation": "CREATE",
                    "amount": "1064.00",
                    "pos": "postman"
                }
            ],
            "amount": "1064.00",
            "status": "CAPTURED",
            "createdAt": "2019-01-28T10:03:28Z",
            "updatedAt": "2019-01-28T10:03:31Z"
        }
    ],
    "createdAt": "2019-01-28T10:03:20Z",
    "_links": {
        "addTransaction": {
            "href": "https://dev.devstage.entur.io/v1/payments/13422/transactions"
        },
        "self": {
            "href": "https://dev.devstage.entur.io/sales/v1/payments/13422/transactions/12735"
        },
        "payment": {
            "href": "https://dev.devstage.entur.io/sales/v1/payments/13422"
        }
    }
}`}</pre>
    </ExpandablePanel>
    <h3 {...{
      "id": "generate-and-distribute-ticket-distributions"
    }}>{`Generate and Distribute ticket distributions`}</h3>
    <p><a parentName="p" {...{
        "href": "#table-of-contents"
      }}>{`(back to table of contents)`}</a></p>
    <p>{`Ticket Distributions are automatically generated based on order events, and a request to this endpoint can be made to distribute them to NOD.
Use the `}<inlineCode parentName="p">{`orderId`}</inlineCode>{` and `}<inlineCode parentName="p">{`orderVersion`}</inlineCode>{` from the previous requests. `}<inlineCode parentName="p">{`orderVersion`}</inlineCode>{` is required and has to be the newest version of the order, otherwise, it will fail.`}</p>
    <p>{`It is possible to specify a `}<inlineCode parentName="p">{`deliverAfter`}</inlineCode>{` in order to specify the earliest time it should be possible to pick up the ticket. This is useful if you for example are making a PDF ticket so you need to pick up the QR code immediately.`}</p>
    <p>{`You can also specify a new start date for a specific order line. This is only allowed for certain products (with the usage trigger SPECIFIED_START_DATE), and it will return Bad Request for other products.
If the tickets already were distributed it will cancel the existing ones and create and distribute new ticket distributions with the new start date. Bad Request is returned if the tickets already were picked up from NOD.`}</p>
    <p>{`Any updates to the order – order lines added or removed, or supplement products added or removed to an order line – after the ticket has been distributed,
will cause changes in the ticket distributions for that order. Because of this, the client should periodically verify using this endpoint that the tickets
displayed to the user remain in the same order line version and that its status is still active. The field `}<inlineCode parentName="p">{`distributionStatus`}</inlineCode>{` in the ticket distribution
should remain in `}<inlineCode parentName="p">{`DISTRIBUTED`}</inlineCode>{` status as long as the ticket is valid. If the order line version has been updated, a new ticket needs to be acquired from NOD to
get the updated QR code. Tickets with distributionStatus set to `}<inlineCode parentName="p">{`CANCELLED`}</inlineCode>{` is no longer valid, and should be removed.`}</p>
    <p><a parentName="p" {...{
        "href": "https://petstore.swagger.io/?url=https://api.entur.io/api-docs/ticket-distribution?group%3dpublic#/Ticket%20Distribution%20API/distributeTicketDistributionGroups"
      }}>{`POST /sales/v1/ticket-distribution-groups/distribute`}</a></p>
    <ExpandablePanel title="Example request" mdxType="ExpandablePanel">
    <pre>{`{
  "orderId": "K2131857",
  "orderVersion": 1
}`}</pre>
    </ExpandablePanel>
    <ExpandablePanel title="Example response" mdxType="ExpandablePanel">
    <pre>
        {`[
      {
          "id": 551524,
          "orderId": "K2131857",
          "orderLineId": "0ee4e845-692e-49ef-af05-e62f11b2b6eb",
          "organisationId": 1,
          "ticketDistributions": [
              {
                  "id": 558944,
                  "distributionStatus": "DISTRIBUTED",
                  "distributionDetails": {
                      "orderGroupId": "6f393a6d-dd98-4acd-aee3-8fe59547f393",
                      "clientSecret": "ny4tst5lz27holrn"
                  },
                  "authority": {
                      "id": "NSB:Authority:NSB"
                  },
                  "topology": {
                      "fromStopPlace": "NSR:StopPlace:506",
                      "fromStopPlaceExt": 1519000,
                      "fromStopPlaceName": "Larvik stasjon",
                      "toStopPlace": "NSR:StopPlace:659",
                      "toStopPlaceExt": 1126000,
                      "toStopPlaceName": "Trondheim S"
                  },
                  "price": {
                      "amount": "1126.00",
                      "taxAmount": "120.64",
                      "taxRate": "12.00",
                      "currency": "NOK"
                  },
                  "startOfValidity": "2019-08-07T09:32:00Z",
                  "endOfValidity": "2019-08-07T18:45:00Z",
                  "purchaseDate": "2019-06-07T07:46:29Z",
                  "networkId": 578003,
                  "productTemplate": 701,
                  "tariffCode": 3718,
                  "fareProduct": {
                      "id": "NSB:PreassignedFareProduct:Ordinary",
                      "version": "NSB:Version:V1",
                      "name": "Ordinær"
                  },
                  "userProfile": {
                      "id": "NSB:UserProfile:Adult",
                      "version": "NSB:Version:V1",
                      "name": "Voksen"
                  },
                  "departureTime": "2019-08-07T09:32:00Z",
                  "arrivalTime": "2019-08-07T18:45:00Z",
                  "deliverAfter": "2019-08-07T09:32:00Z",
                  "reservations": [
                      {
                          "id": 39865,
                          "externalReservationId": "c645ad0b-2021-40fc-a331-f5d91bcb2af0",
                          "seats": [
                              {
                                  "id": 39487,
                                  "seatCode": "3",
                                  "seatNumber": "56",
                                  "railcarNumber": "6",
                                  "seatType": "4"
                              }
                          ],
                          "datedServiceJourneyId": "ENT:DatedServiceJourney:17171199"
                      }
                  ],
                  "supplementProducts": [],
                  "provider": 4,
                  "orderLineVersion": 1
              }
          ],
          "updatedForOrderLineVersion": 1
      }
  ]`}
    </pre>
    </ExpandablePanel>
    <h4 {...{
      "id": "pdf-tickets"
    }}>{`PDF-Tickets`}</h4>
    <p><a parentName="p" {...{
        "href": "https://petstore.swagger.io/?url=https://api.entur.io/api-docs/ticket-distribution?group%3dpublic#/Ticket%20Distribution%20API/sendPDF"
      }}>{`POST /sales/v1/pdf-tickets/{orderId}/send`}</a></p>
    <p>{`As with regular tickets, Ticket Distributions for PDF-tickets are automatically generated based on order events and a request to this endpoint will produce a PDF-representation of the ticket and send it to a provided email address.`}</p>
    <p>{`A `}<inlineCode parentName="p">{`PdfTicketRequest`}</inlineCode>{` needs to specify travellers for all the generated TicketDistributions that exist for an order and the email address where the tickets are to be sent. Optionally one can provide the desired language one would like the ticket to be created in. Supported languages are for now Norwegian (`}<inlineCode parentName="p">{`nob`}</inlineCode>{` , default) and English (`}<inlineCode parentName="p">{`eng`}</inlineCode>{`).`}</p>
    <ExpandablePanel title="Example request" mdxType="ExpandablePanel">
    <pre>{`{
    "travellers": [
        {
            "ticketDistributionId": 123,
            "name": "Test Testesen"
        },
        {
            "ticketDistributionId": 321,
            "name": "Testeline Testesen"
        }
    ],
    "email": "test@testesen.no",
    "pdfLanguage": "eng"
}`}</pre>
    </ExpandablePanel>
    <ExpandablePanel title="Example response" mdxType="ExpandablePanel">
    <pre>{`{
    "from": [
        {
            "address": "no-reply@entur.org",
            "name": "Entur AS"
        }
    ],
    "to": [
        {
            "address": "test@testesen.no",
        }
    "subject": "Entur ticket",
    "body": "Tickets are attached to this email.",
    "attachements": "binary data of PDF"
}`}</pre>
    </ExpandablePanel>
    <h3 {...{
      "id": "custom-entur-properties-in-nod"
    }}>{`Custom Entur properties in NOD`}</h3>
    <p><a parentName="p" {...{
        "href": "#table-of-contents"
      }}>{`(back to table of contents)`}</a></p>
    <p>{`When tickets are distributed to NOD, a set of custom properties are used as described in the table below.
Note that the properties used are dependent on the type of ticket distributed.
If the ticket is a zone ticket, from/to tariff zone name, the number of zones, and validity in all zones flag will be used.
If the ticket is `}<em parentName="p">{`not`}</em>{` a zone ticket, to/from place name and departure/arrival time is used.`}</p>
    <p>{`Also note `}<em parentName="p">{`entur_combinedTicket`}</em>{` which is set to `}<em parentName="p">{`true`}</em>{` for through fare tickets where the price is not given by individual tickets, but rather the price of all the parts of the combined ticket.`}</p>
    <p>{`See the `}<a parentName="p" {...{
        "href": "pages-nod-intro"
      }}>{`NOD (National Order Database)`}</a>{` section for more information.`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Key`}</th>
          <th parentName="tr" {...{
            "align": "center"
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Comment`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`entur_ticketDistributionId`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Reference to ticket distribution.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`entur_orderId`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Reference to order.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`entur_purchaseDate`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`dateTime`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Purchased date of ticket.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`entur_amount`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Amount paid for this ticket.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`entur_taxAmount`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Tax amount paid for this ticket.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`entur_authority`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Reference to authority.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`entur_reservations`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`json`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`JSON Node detailing reservation and seats for each leg of the journey.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`entur_supplements`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`String with names of each supplement product on this journey.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`entur_taxRate`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Tax rate used to calculate the tax amount for this ticket.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`entur_fromTariffZoneName`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`From tariff zone name for this ticket. Only if a zone ticket.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`entur_toTariffZoneName`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`To tariff zone name for this ticket. Only if a zone ticket.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`entur_numberOfZones`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`int`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Number of tariff zones ticket is valid for.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`entur_fromPlaceName`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`From stop place name this ticket. Only if journey between stop place.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`entur_departureTime`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`dateTime`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Departure time for this journey. Only if journey between stop place.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`entur_toPlaceName`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`To stop place name this ticket. Only if journey between stop place.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`entur_arrivalTime`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`dateTime`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Arrival time for this journey. Only if journey between stop place.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`entur_fareProductName`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Name of fare product bought`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`entur_userProfileName`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Name of the user profile which was used for this ticket`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`entur_groupName`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Name of group ticket.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`entur_groupDescription`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Description of group ticket.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`entur_ticketType`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`What type of ticket this is. Possible values are SINGLE and PERIODIC`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`entur_validAllZones`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`boolean`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`If ticket should be valid in all zones in system, only in special case`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`entur_combinedTicket`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`boolean`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`If the ticket is part of 2 or more tickets that do not have individual prices`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`entur_combinedTicketName`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`If combinedTicket=true, the name for the package is shown here`}</td>
        </tr>
      </tbody>
    </table>
    <h2 {...{
      "id": "searching-for-orders"
    }}>{`Searching for Orders`}</h2>
    <p><a parentName="p" {...{
        "href": "#table-of-contents"
      }}>{`(back to table of contents)`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://petstore.swagger.io/?url=https://api.entur.io/api-docs/order?group%3dpublic#/Order%20API/getOrders"
      }}>{`GET /sales/v1/orders?`}</a></p>
    <p>{`Searching for orders can be accomplished by using multiple filter queries to narrow down a search. Complex filters can be created by using multiple query parameters, even by name. All query parameters are joined together using the operator AND.`}</p>
    <p>{`In the example below a complex query is created by using multiple query parameters to find all orders in status DRAFT, version 2 which has a total amount greater or equal to 100 and lower than 200.`}</p>
    <pre><code parentName="pre" {...{}}>{`GET /sales/v1/orders?status=DRAFT&version=gt:1
    &totalAmount=gte:100.00&totalAmount=lt:200.00
`}</code></pre>
    <h2 {...{
      "id": "refunding"
    }}>{`Refunding`}</h2>
    <p><a parentName="p" {...{
        "href": "#table-of-contents"
      }}>{`(back to table of contents)`}</a></p>
    <p>{`By performing a refund of an order, the affected order lines and reservations will be cancelled along with the ticket.
The balance after performing the refund, according to the refund rules, will be credited back to the customer.`}</p>
    <p>{`To refund an order, either partially or in its entirety, the following conditions must be met:`}</p>
    <ul>
      <li parentName="ul">{`The latest version of the order is CONFIRMED. This should be the case after completing the sales steps leading to and including `}<a parentName="li" {...{
          "href": "#capture-transaction"
        }}>{`capturing transaction`}</a>{`.`}</li>
      <li parentName="ul">{`The affected order lines are CONFIRMED and not CANCELLED`}</li>
      <li parentName="ul">{`The affected order lines have active refund options`}</li>
    </ul>
    <p>{`To cancel an order item that is not refundable, you must manually cancel and balance the order yourself. See `}<a parentName="p" {...{
        "href": "#cancellation"
      }}>{`cancellation guide`}</a>{`.`}</p>
    <h3 {...{
      "id": "get-refund-options"
    }}>{`Get refund options`}</h3>
    <p><a parentName="p" {...{
        "href": "#table-of-contents"
      }}>{`(back to table of contents)`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://petstore.swagger.io/?url=https://api.entur.io/api-docs/refund?group%3dpublic#/Refund%20API%20Public/getAllPossibleRefundOptionsForOrder"
      }}>{`GET /sales/v1/refunds/options/{orderId}`}</a></p>
    <p>{`Get all refund options that are active now for a given order, with optional orderLineIds query parameter.
Omitting the orderLineIds query param will return all refund options for all order lines on the latest version of the order.
Refund options, at the time of writing, will only give you a maximum of `}<strong parentName="p">{`one`}</strong>{` RefundOption per order line.
This may be expanded in the future so one could refund parts of an order line. This corresponds to the fact that the refundOperation-field in PerformRefundEntry is a list.`}</p>
    <BannerAlertBox variant="warning" mdxType="BannerAlertBox">The fee must be subtracted from refundAmount to get the final amount available for refund.
A negative amount can not be refunded.</BannerAlertBox>
    <br />
    <p><inlineCode parentName="p">{`GET /sales/v1/refunds/options/{orderId}?orderlineIds=ec943c0e-04a2-4585-a7b4-4f76d9bfa8e2,fb8b7a0d-390c-41da-9034-0541599db970`}</inlineCode></p>
    <ExpandablePanel title="Example response" mdxType="ExpandablePanel">
    <pre>{`{
    "orderRef": {
        "id": "72ZXTR7T",
        "version": 1
    },
    "options": [
        {
            "orderLineRef": {
                "id": "ec943c0e-04a2-4585-a7b4-4f76d9bfa8e2",
                "version": 1
            },
            "product": {
                "id": "NSB:PreassignedFareProduct:Ordinary",
                "version": "NSB:Version:V1",
                "name": "Ordinær"
            },
            "refundRule": {
                "id": "NSB:Refunding:Cancellation",
                "version": "NSB:Version:V1",
                "name": "Angrefrist"
            },
            "refundAmount": "1126.00",
            "fee": "0.00",
            "expiresAt": "2019-07-03T18:37:14Z",
            "productElementIdentifier": {
                "level": "FARE_PRODUCT",
                "fareproductIndex": 0,
                "validableElementIndex": null,
                "fareStructureElementIndex": null
            },
            "additionalAffectedElementsOnLevel": []
        }
    ],
    "optionIdsThatCanBePerformedTogether": []
}`}</pre>
    </ExpandablePanel>
    <h3 {...{
      "id": "refund-order-line"
    }}>{`Refund order line`}</h3>
    <p><a parentName="p" {...{
        "href": "#table-of-contents"
      }}>{`(back to table of contents)`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://petstore.swagger.io/?url=https://api.entur.io/api-docs/refund?group%3dpublic#/Refund%20API%20Public/refundOrder"
      }}>{`PUT /sales/v1/refunds/{orderId}`}</a></p>
    <p>{`To refund one or more order lines, the request body must contain a list of refund entries with reference to the order line, the refund option, and the refund rule that should be used.
These fields are available by fetching refund options for the order.`}</p>
    <p>{`To refund the entire order, a refundOption per order line must be selected.
There is no shortcut to this because different products have different rules for whether a fee must be added to the order.`}</p>
    <p><inlineCode parentName="p">{`PUT /sales/v1/refunds/{orderId}`}</inlineCode></p>
    <ExpandablePanel title="Example request body" mdxType="ExpandablePanel">
    <pre>
        {`[
    {
        "orderLineRef": {
            "id": "ec943c0e-04a2-4585-a7b4-4f76d9bfa8e2",
            "version": 1
        },
        "refundOperations": [
            {
                "level": "FARE_PRODUCT",
                "fareproductIndex": 0
            }
        ],
        "refundRule": {
            "id": "NSB:Refunding:Cancellation",
            "version": "NSB:Version:V1",
            "name": "Angrefrist"
        }
    }
]`}
    </pre>
    </ExpandablePanel>
    <ExpandablePanel title="Example response" mdxType="ExpandablePanel">
    <pre>{`{
    "orderId": "72ZXTR7T",
    "affectedOrderLineIds": [
        "ec943c0e-04a2-4585-a7b4-4f76d9bfa8e2"
    ],
    "creditId": 13396,
    "affectedPaymentAndPaymentTransactionIds": {
        "135052": [
            132630
        ]
    },
    "disabledTicketIds": [
        607735
    ]
}`}</pre>
    </ExpandablePanel>
    <h2 {...{
      "id": "manual-refunding"
    }}>{`Manual Refunding`}</h2>
    <p><a parentName="p" {...{
        "href": "#table-of-contents"
      }}>{`(back to table of contents)`}</a></p>
    <p>{`It is not always possible to use the Refund-API to refund an order. For example when an order consists of products from a third party source. In these cases, it is possible to perform a manual refund process.`}</p>
    <p>{`When performing a manual refund, the tickets (ticket-distributions) will automatically be cancelled.`}</p>
    <p>{`To perform a manual refund, the following steps must be taken:`}</p>
    <ol>
      <li parentName="ol">
        <p parentName="li">{`Cancel the order lines that should be refunded, and add a fee if there should be a cancellation fee. See section 1. and 2. under `}<a parentName="p" {...{
            "href": "#cancellation"
          }}>{`cancellation`}</a></p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Create a credit with amount equal to remaining amount to be credited (after fees have been added):`}</p>
        <p parentName="li"><a parentName="p" {...{
            "href": "https://petstore.swagger.io/?url=https://api.entur.io/api-docs/payment%3Fgroup%3Dpublic#/Payment%20API%20Public/createCredit"
          }}>{`POST /v1/credits`}</a></p>
        <ExpandablePanel title="Example request body" mdxType="ExpandablePanel">
          <pre>{`{
    "totalCreditAmount": "38.00",
    "orderId": "FWDZED4Y",
    "creditTransactions": [
        {
        "paymentTransactionId": 522487,
        "amount": "38.00"
        }
    ],
    "orderVersion": 2
}`}</pre>
        </ExpandablePanel>
        <ExpandablePanel title="Example response" mdxType="ExpandablePanel">
          <pre>{`{
    "creditId": 217004,
    "totalCreditAmount": "329.00",
    "orderId": "FWDZED4Y",
    "orderVersion": 2,
    "organisationId": 1,
    "creditTransactions": [
        {
            "paymentId": 542361,
            "paymentTransactionId": 522487,
            "amount": "38.00",
            "currency": "NOK",
            "status": "CREATED",
            "reimbursementMethod": "VISA",
            "reimbursementTypeGroup": "PAYMENTCARD",
            "rrn": "000000522487",
            "createdAt": "2021-02-10T07:19:32Z"
        }
    ],
    "currency": "NOK",
    "active": true,
    "_links": {
        "self": {
            "href": "https://api.staging.entur.io/sales/v1/credits/217004"
        },
        "execute": {
            "href": "https://api.staging.entur.io/sales/v1/credits/217004/execute"
        }
    }
}`}</pre>
        </ExpandablePanel>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Execute the credit:`}</p>
        <p parentName="li"><a parentName="p" {...{
            "href": "https://petstore.swagger.io/?url=https://api.entur.io/api-docs/payment%3Fgroup%3Dpublic#/Payment%20API%20Public/executeCredit"
          }}>{`POST /v1/credits/{creditId}/execute`}</a></p>
        <ExpandablePanel title="Example response" mdxType="ExpandablePanel">
          <pre>{`{
    "creditId": 217004,
    "totalCreditAmount": "38.00",
    "orderId": "FWDZED4Y",
    "orderVersion": 2,
    "organisationId": 1,
    "creditTransactions": [
        {
            "paymentId": 542361,
            "paymentTransactionId": 522487,
            "amount": "38.00",
            "currency": "NOK",
            "creditedAt": "2021-02-10T07:30:24Z",
            "status": "CREDITED",
            "reimbursementMethod": "VISA",
            "reimbursementTypeGroup": "PAYMENTCARD",
            "rrn": "000000522487",
            "createdAt": "2021-02-10T07:19:32Z"
        }
    ],
    "currency": "NOK",
    "active": true,
    "_links": {
        "transactionSummary": {
            "href": "https://api.staging.entur.io/v1/payments/542361/transactions/522487"
        },
        "self": {
            "href": "https://api.staging.entur.io/sales/v1/credits/217004"
        }
    }
}`}</pre>
        </ExpandablePanel>
      </li>
    </ol>
    <h2 {...{
      "id": "cancellation"
    }}>{`Cancellation`}</h2>
    <p><a parentName="p" {...{
        "href": "#table-of-contents"
      }}>{`(back to table of contents)`}</a></p>
    <p>{`This guide shows you how to cancel order lines. This can be used, for example, when an order line has non-refundable products.`}</p>
    <ol>
      <li parentName="ol">
        <p parentName="li">{`Manually cancel desired order lines on the order:`}</p>
        <p parentName="li"><a parentName="p" {...{
            "href": "https://petstore.swagger.io/?url=https://api.entur.io/api-docs/order?group%3dpublic#/Order%20API/cancelOrderLines"
          }}>{`POST /v1/orders/{orderId}/orderlines/{orderLineId}/cancel`}</a></p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Add a fee corresponding to the price of the order lines cancelled, so that the balance equals zero. Always set type as MANUAL (see example request):`}</p>
        <p parentName="li"><a parentName="p" {...{
            "href": "https://petstore.swagger.io/?url=https://api.entur.io/api-docs/order?group%3dpublic#/Order%20API/addFeeToOrder"
          }}>{`POST /v1/orders/{orderId}/fees`}</a></p>
        <p parentName="li">{`Let's say we want to cancel an order with two order lines. If the first costs 199 NOK and the second costs 200 NOK, then the price of the fee must be as shown below 399 NOK.`}</p>
        <ExpandablePanel title="Example request" mdxType="ExpandablePanel">
          <pre>{`{
    "price": {
    "amount": 399,
    "taxAmount": 36.27,
    "taxRate": 12,
    "taxCode": 12,
    "currency": "NOK"
    },
    "type": "MANUAL",
    "externalRef": {
    "id": "string",
    "version": "string"
    },
    "reason": "Any reason why the cancellation was done",
    "orderLineIds": [
    "orderLineId1",
    "orderLineId2"
    ],
    "orderVersion": 2
}`}</pre>
        </ExpandablePanel>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Confirm the order`}</p>
        <p parentName="li"><a parentName="p" {...{
            "href": "https://petstore.swagger.io/?url=https://api.entur.io/api-docs/order?group%3dpublic#/Order%20API/confirmOrder"
          }}>{`POST /v1/orders/{orderId}/confirm`}</a></p>
      </li>
    </ol>
    <h2 {...{
      "id": "concurrent-order-changes"
    }}>{`Concurrent order changes`}</h2>
    <p><a parentName="p" {...{
        "href": "#table-of-contents"
      }}>{`(back to table of contents)`}</a></p>
    <p>{`In general, there are no mechanisms to prevent concurrent changes to an order, even by different clients.
However, a locking mechanism is in place to prevent order changes while processing a payment or credit.
Locking an order does not mean the payment is locked; i.e., you can still edit a payment while an order is locked.
While an order is locked, some changes to the order cannot be made until it is unlocked.`}</p>
    <BannerAlertBox variant="warning" mdxType="BannerAlertBox">
    Unsuccessful attempts to make changes to a locked order will return 423 LOCKED.
    <ExpandablePanel title="Example 423 Locked response" mdxType="ExpandablePanel">
    <pre>{`{
        "timestamp": "2022-11-23T08:46:31Z",
        "status": 423,
        "error": "Locked",
        "exception": "OrderLockedException",
        "message": "Order 642FJ1FR is locked and cannot be edited due to PaymentActivated",
        "path": "/v1/reserve-offers"
    }`}</pre>
    </ExpandablePanel>
    </BannerAlertBox>
    <br />
    <BannerAlertBox variant="info" mdxType="BannerAlertBox">An order will automatically unlock after 3 minutes.</BannerAlertBox>
    <h3 {...{
      "id": "updating-a-locked-order"
    }}>{`Updating a locked order`}</h3>
    <p><a parentName="p" {...{
        "href": "#table-of-contents"
      }}>{`(back to table of contents)`}</a></p>
    <p>{`To modify a locked order (i.e., where a payment or credit have been created) you must deactivate the cause of the lock.
While the payment/credit is not active, you can edit an order as you please.
After the order has been satisfactorily changed, you must reactivate the payment/credit by setting it to active and updating it with the new total amount of the order.`}</p>
    <ol>
      <li parentName="ol">
        <p parentName="li">{`Deactivate the cause of the lock, either/or`}</p>
        <ol parentName="li">
          <li parentName="ol">{`Payment by `}<inlineCode parentName="li">{`POST`}</inlineCode>{`-ing to `}<a parentName="li" {...{
              "href": "https://petstore.swagger.io/?url=https://api.entur.io/api-docs/payment%3Fgroup%3Dpublic#/Payment%20API%20Public/deactivatePayment"
            }}>{`/v1/payments/{paymentId}/deactivate`}</a>{` with an empty body`}</li>
          <li parentName="ol">{`Credit by `}<inlineCode parentName="li">{`POST`}</inlineCode>{`-ing to `}<a parentName="li" {...{
              "href": "https://petstore.swagger.io/?url=https://api.entur.io/api-docs/payment%3Fgroup%3Dpublic#/Payment%20API%20Admin/deactivateCredit"
            }}>{`/v1/credits/{creditId}/deactivate`}</a>{` with an empty body`}</li>
        </ol>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Wait for the order to be unlocked`}</p>
        <ol parentName="li">
          <li parentName="ol">{`For Entur applications: wait for a `}<inlineCode parentName="li">{`OrderUnlocked`}</inlineCode>{` kafka event with the correct `}<inlineCode parentName="li">{`orderId`}</inlineCode></li>
          <li parentName="ol">{`For external applications: it shouldn't take tooo long :)`}</li>
        </ol>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Edit your order as normal`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Reactivate the payment with the new total amount of the order and `}<inlineCode parentName="p">{`active`}</inlineCode>{` set to `}<inlineCode parentName="p">{`true`}</inlineCode></p>
        <p parentName="li"><a parentName="p" {...{
            "href": "https://petstore.swagger.io/?url=https://api.entur.io/api-docs/payment%3Fgroup%3Dpublic#/Payment%20API%20Public/mergePatchPayment"
          }}>{`PATCH /sales/v1/payments/{paymentId}`}</a></p>
        <ExpandablePanel title="Example request" mdxType="ExpandablePanel">
          <pre>{`{
    "totalAmount": "747.00",
    "active": true
}`}</pre>
        </ExpandablePanel>
        <ExpandablePanel title="Example response" mdxType="ExpandablePanel">
          <pre>{`{
    "_links": {
        "addTransaction": {
            "href": "https://payment.staging.entur.io/sales/v1/payments/778914/transactions"
        },
        "self": {
            "href": "https://payment.staging.entur.io/sales/v1/payments/778914"
        }
    },
    "active": true,
    "createdAt": "2022-11-23T08:26:11Z",
    "currency": "NOK",
    "orderId": "642FJ1FR",
    "orderVersion": 1,
    "organisationId": 1,
    "paymentId": 778914,
    "totalAmount": "747.00",
    "transactionHistory": [],
    "updatedAt": "2022-11-23T08:33:40Z"
}`}</pre>
        </ExpandablePanel>
        <br />
        <BannerAlertBox variant="warning" mdxType="BannerAlertBox">
    If the new totalAmount sent does not match the balance of the order a 400 Bad Request is returned.
    <br />
    <ExpandablePanel title="Example 400 Bad Request response" mdxType="ExpandablePanel">
    <pre>{`{
        "error": "Bad Request",
        "exception": "org.entur.payment.faulthandling.exceptions.ValidationException",
        "message": "Payment amount '999.00' does not equal order balance '747.00'",
        "path": "/v1/payments/778914",
        "status": 400,
        "timestamp": "2022-11-23T08:43:57Z"
    }`}</pre>
    </ExpandablePanel>
        </BannerAlertBox>
      </li>
    </ol>
    <h3 {...{
      "id": "order-locking-overview"
    }}>{`Order Locking Overview`}</h3>
    <p><a parentName="p" {...{
        "href": "#table-of-contents"
      }}>{`(back to table of contents)`}</a></p>
    <h4 {...{
      "id": "events"
    }}>{`Events`}</h4>
    <p>{`The following kafka events relate to order locking`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Action`}</th>
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Order state`}</th>
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Source`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Create payment`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Locked`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Payment`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Create credit`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Locked`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Payment`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Payment activated`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Locked`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Payment`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Credit activated`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Locked`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Payment`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Order confirmed after payment`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Unlocked`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Payment`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Order confirmed after credit`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Unlocked`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Payment`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Payment deactivated`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Unlocked`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Payment`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Credit deactivated`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Unlocked`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Payment`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Order Locked`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Locked`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Order`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Order Unlocked`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Unlocked`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Order`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Order Reset`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Unlocked`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Order`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Payment added to order`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Unlocked`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Order`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Credit added to order`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Unlocked`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Order`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Timeout`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Unlocked`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`SPM`}</td>
        </tr>
      </tbody>
    </table>
    <h4 {...{
      "id": "operations"
    }}>{`Operations`}</h4>
    <p>{`How the following changes to an order behaves in relation to order locking.`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Operation`}</th>
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Allowed while locked`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Add credit to order`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Yes`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Add payment to order`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Yes`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Add reservations`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Yes`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Add traveller to order`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Yes`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Confirm order`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Yes`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Remove reservation`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Yes`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Remove traveller from order`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Yes`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Reset order`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Yes`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Update order`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Yes`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Update reservation`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Yes`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Update traveller`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Yes`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Add fee`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`No`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Add order lines`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`No`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Cancel order lines`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`No`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Import order`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`No`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Remove fee`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`No`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Remove order line`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`No`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Replace order lines`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`No`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Undo replace order lines`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`No`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Update order line`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`No`}</td>
        </tr>
      </tbody>
    </table>
    <h2 {...{
      "id": "period-ticket"
    }}>{`Period Ticket`}</h2>
    <p><a parentName="p" {...{
        "href": "#table-of-contents"
      }}>{`(back to table of contents)`}</a></p>
    <p>{`The sales process for period tickets is mostly the same as the sales process for regular single tickets, differing in how offers are requested and optional start time.
For more information about how to construct the request to get offers, consult the `}<a parentName="p" {...{
        "href": "pages-offers-guides"
      }}>{`Offers guides`}</a>{`.`}</p>
    <h2 {...{
      "id": "zero-ticket"
    }}>{`Zero Ticket`}</h2>
    <p><a parentName="p" {...{
        "href": "#table-of-contents"
      }}>{`(back to table of contents)`}</a></p>
    <p>{`A zero ticket is an order with totalAmount equal to zero.
This can be the case for personal tickets or alternative transport where the operator has to create a new ticket if the original journey did not take place.
When creating a zero ticket;`}</p>
    <ul>
      <li parentName="ul">{`You do not need to create a payment`}</li>
      <li parentName="ul">{`You have to manually confirm the order: `}<inlineCode parentName="li">{`POST /sales/v1/orders{orderId}/confirm`}</inlineCode></li>
    </ul>
    <h3 {...{
      "id": "get-offer-1"
    }}>{`Get Offer`}</h3>
    <p><a parentName="p" {...{
        "href": "#table-of-contents"
      }}>{`(back to table of contents)`}</a></p>
    <p><inlineCode parentName="p">{`POST /offers/v2/search/zones`}</inlineCode><br />{`
`}<a parentName="p" {...{
        "href": "https://petstore.swagger.io/?url=https://api.entur.io/api-docs/offers"
      }}>{`See the API reference on the Swagger Petstore`}</a></p>
    <p>{`This example requests an offer for an adult seven days ticket over two zones.
Here the request contains `}<inlineCode parentName="p">{`recommendationConfig`}</inlineCode>{` with `}<inlineCode parentName="p">{`typesOfRecommendation`}</inlineCode>{` set to `}<inlineCode parentName="p">{`CHEAPEST`}</inlineCode>{`, durationTypes set to `}<inlineCode parentName="p">{`WEEKLY_PASS`}</inlineCode>{` and `}<inlineCode parentName="p">{`onlyIncludeRecommendedOffers`}</inlineCode>{` set to `}<inlineCode parentName="p">{`true`}</inlineCode>{`.
This combination will return the cheapest weekly pass offer, as the request would otherwise return all available offers between the two zones for an adult (eg. Single ticket, 7 days pass etc.).`}</p>
    <ExpandablePanel title="Example request" mdxType="ExpandablePanel">
    <pre>{`{
    "from": "RUT:TariffZone:1",
    "to": "RUT:TariffZone:2V",
    "travelDate": "2024-04-30T12:59:34.081Z",
    "travellers": [
        {
            "userType": "ADULT"
        }
    ],
    "recommendationConfig": {
        "categorySpec": {
            "typesOfRecommendation": [
                "CHEAPEST"
            ],
            "durationTypes": [
                "WEEKLY_PASS"
            ]
        },
        "ruleSpec": {
            "onlyIncludeRecommendedOffers": true
        }
    }
}`}</pre>
    </ExpandablePanel>
    <ExpandablePanel title="Example response" mdxType="ExpandablePanel">
    <pre>{`{
    "offers": [
        {
            "id": "e4fb23e4-ac0b-47ee-a503-94acced649be",
            "name": "7-dagersbillett",
            "description": "7-dagersbillett",
            "price": {
                "amount": "615.00",
                "currency": "NOK"
            },
            "availableFulfillmentMethods": [
                {
                    "id": "ENT:FulfilmentMethod:FysiskBillett",
                    "version": "ENT:Version:FM-FysiskBillett-1"
                }
            ],
            "geographicalValidity": {
                "zonalValidity": {
                    "zones": [
                        "RUT:TariffZone:1",
                        "RUT:TariffZone:2V"
                    ]
                }
            },
            "preassignedProducts": [
                {
                    "id": "RUT:PreassignedFareProduct:c9538f6d",
                    "version": "RUT:Version:FP-3d30b34f-f715-47c7-a0a0-43b4e50e6949",
                    "name": "7-dagersbillett",
                    "description": "- Billetten gjelder for et ubegrenset antall reiser i valgte soner [Billettbetingelser og reisevilkår](https://ruter.no/kjop-billett/billetter-og-priser/7-dagersbillett/)",
                    "properties": {
                        "isExchangeable": false,
                        "isRefundable": true,
                        "userProfileSummary": {
                            "id": "RUT:UserProfile:4e12a0b8",
                            "version": "RUT:Version:UPR-54803cde-dc9d-4e5f-b961-6866ab1a2255",
                            "name": "Voksen",
                            "userType": "ADULT"
                        },
                        "organisation": {
                            "id": "RUT:Authority:RUT",
                            "name": "Ruter"
                        },
                        "duration": "PT168H",
                        "fareClasses": [
                            "STANDARD_CLASS"
                        ],
                        "durationType": "WEEKLY_PASS"
                    },
                    "geographicalValidity": {
                        "zonalValidity": {
                            "zones": [
                                "RUT:TariffZone:1",
                                "RUT:TariffZone:2V"
                            ]
                        }
                    }
                }
            ],
            "optionalProductsSelectableIds": [],
            "travellerMapping": [
                {
                    "travellerIds": [
                        "58fb75cd-0711-480a-8605-71ddf82005b7"
                    ],
                    "maxNumberOfTravellers": 1,
                    "minNumberOfTravellers": 1,
                    "userType": "ADULT"
                }
            ]
        }
    ],
    "recommendations": [
        {
            "geographicalValidityCovered": {
                "serviceJourneys": [],
                "zonalValidity": {
                    "zones": [
                        "RUT:TariffZone:1",
                        "RUT:TariffZone:2V"
                    ]
                }
            },
            "typeOfRecommendation": "CHEAPEST",
            "durationType": "WEEKLY_PASS",
            "offersToBuy": [
                {
                    "id": "e4fb23e4-ac0b-47ee-a503-94acced649be",
                    "numberToBuy": 1,
                    "selectableProductIds": [],
                    "possibleTravellerIds": [
                        [
                            "58fb75cd-0711-480a-8605-71ddf82005b7"
                        ]
                    ]
                }
            ]
        }
    ]
}`}</pre>
    </ExpandablePanel>
    <p>{`The Offers API supports other ways to obtain period tickets, such as between specific stop places or for a specific authority.
For more information, check the `}<a parentName="p" {...{
        "href": "pages-offers-docs-guides-v2"
      }}>{`Offers guides`}</a>{`.`}</p>
    <h3 {...{
      "id": "reserve-offer-and-handle-payment"
    }}>{`Reserve offer and handle payment`}</h3>
    <p><a parentName="p" {...{
        "href": "#table-of-contents"
      }}>{`(back to table of contents)`}</a></p>
    <p>{`The next steps to complete a sale for period tickets are the same as for an offer with a trip, from `}<a parentName="p" {...{
        "href": "#create-order"
      }}>{`creating an order`}</a>{` to `}<a parentName="p" {...{
        "href": "#capture-transaction"
      }}>{`capturing transaction`}</a>{`.`}</p>
    <h3 {...{
      "id": "distribute-ticket"
    }}>{`Distribute ticket`}</h3>
    <p><a parentName="p" {...{
        "href": "#table-of-contents"
      }}>{`(back to table of contents)`}</a></p>
    <p>{`When distributing period tickets it is (for some of the products) possible to provide a specific start time.
As described in the `}<a parentName="p" {...{
        "href": "#distribute-ticket-distribution"
      }}>{`Distribute ticket distribution`}</a>{` section, the usage trigger of the products determines if this is possible.`}</p>
    <p><inlineCode parentName="p">{`startOfValidity`}</inlineCode>{` can be specified for every order line/ticket and sets when the ticket should be valid from. Note that `}<inlineCode parentName="p">{`deliverAfter`}</inlineCode>{` can not be set to after `}<inlineCode parentName="p">{`startOfValidity`}</inlineCode>{`.`}</p>
    <p><inlineCode parentName="p">{`POST /sales/v1/ticket-distribution-groups/distribute`}</inlineCode></p>
    <ExpandablePanel title="Example request" mdxType="ExpandablePanel">
    <pre>{`{
    "orderId": "IHY9GL26",
    "orderVersion": "1",
    "deliverAfter": "2019-07-07T14:00:00Z",
    "orderLineDistributionRequests": [
        {
            "orderLineId": "d801d465-8691-4ad2-8796-f7eec88a80b1",
            "startOfValidity": "2019-07-07T14:00:00Z"
        }
    ]
}`}</pre>
    </ExpandablePanel>
    <ExpandablePanel title="Example response" mdxType="ExpandablePanel">
    <pre>{`[
    {
        "id": 73781,
        "orderId": "U5D2YBWS",
        "orderLineId": "d801d465-8691-4ad2-8796-f7eec88a80b1",
        "organisationId": 1,
        "ticketDistributions": [
            {
                "id": 73611,
                "distributionStatus": "DISTRIBUTED",
                "distributionDetails": {
                    "orderGroupId": "4abf683d-99c1-497e-92e1-ec4e5836a822",
                    "clientSecret": "wblkt2ub7mfl1mj5"
                },
                "authority": {
                    "id": ""
                },
                "topology": {
                    "fromTariffZone": "RUT:TariffZone:1",
                    "fromTariffZoneExt": 227,
                    "fromTariffZoneName": "1",
                    "toTariffZone": "RUT:TariffZone:1",
                    "toTariffZoneExt": 227,
                    "toTariffZoneName": "1",
                    "distance": 1
                },
                "price": {
                    "amount": "285.00",
                    "taxAmount": "30.54",
                    "taxRate": "12.00",
                    "currency": "NOK"
                },
                "startOfValidity": "2019-07-07T14:00:00Z",
                "endOfValidity": "2019-07-14T14:00:00Z",
                "purchaseDate": "2019-07-03T10:43:14Z",
                "networkId": 578000,
                "productTemplate": 18,
                "tariffCode": 21204,
                "fareProduct": {
                    "id": "RUT:PreassignedFareProduct:Ruter7Days",
                    "version": "RUT:Version:V1",
                    "name": "Ruter 7-dagersbillett"
                },
                "userProfile": {
                    "id": "RUT:UserProfile:Adult",
                    "version": "RUT:Version:V1",
                    "name": "Voksen"
                },
                "deliverAfter": "2019-07-07T14:00:00Z",
                "reservations": [],
                "supplementProducts": [],
                "provider": 3,
                "orderLineVersion": 1,
                "usageTrigger": "SPECIFIED_START_DATE",
                "ticketWithZones": true
            }
        ],
        "updatedForOrderLineVersion": 1
    }
]`}</pre>
    </ExpandablePanel>
    <h3 {...{
      "id": "send-receipt"
    }}>{`Send Receipt`}</h3>
    <p><a parentName="p" {...{
        "href": "#table-of-contents"
      }}>{`(back to table of contents)`}</a></p>
    <BannerAlertBox variant="warning" title="Zero ticket receipt" mdxType="BannerAlertBox">
    When sending receipt for zero tickets you always have to do a manual POST to
    /v1/receipts/send with header receiptType set to SALE and turn OFF automatic
    sending of receipt.
    </BannerAlertBox>
    <br />
    <p>{`Automatic sending of receipt does not work for zero tickets because the app will, due to missing payment and credit, believe that it is a receipt of type CHANGE.
Because of this you `}<em parentName="p">{`always`}</em>{` have to do a manual POST to `}<inlineCode parentName="p">{`/v1/receipts/send`}</inlineCode>{` with header `}<inlineCode parentName="p">{`receiptType`}</inlineCode>{` set to SALE when sending receipt for zero tickets
and make sure that automatic sending of receipt is turned OFF for the given combination of orgId, dci and pos.`}</p>
    <ExpandablePanel title="Example request" mdxType="ExpandablePanel">
    <pre>{`{
  "orderId": "ABCD1234",
  "orderVersion": 1,
  "distribution": {
    "email": "example@entur.org",
    "telephone": {
      "countryCode": "47",
      "number": "61051910"
    }
  },
  "receiptLanguage": "nob",
  "serialNumber": "string",
  "copyReceipt": true
}`}</pre>
    </ExpandablePanel>
    <ExpandablePanel title="Example response" mdxType="ExpandablePanel">
    <pre>{`{
  "reference": "a49f7259-7285-42ce-b7b0-e48a77fbfb39"
}`}</pre>
    </ExpandablePanel>
    <h2 {...{
      "id": "payment-methods"
    }}>{`Payment Methods`}</h2>
    <p><a parentName="p" {...{
        "href": "#table-of-contents"
      }}>{`(back to table of contents)`}</a></p>
    <p>{`In order to be able to complete payments using the Sales API, each clientId must have configured which Payment Methods they should have access to.`}</p>
    <p>{`Each Payment Type must be configured for the client by Entur. To get a list of all payment methods enabled for the client, use: `}<inlineCode parentName="p">{`GET /sales/v1/payment-methods`}</inlineCode>{`. Please contact your Partner Assistant if you need any Payment Methods`}</p>
    <p>{`The payment methods are differentiated between imported payments which are payments that have already been executed and payments executed by the API.
PaymentMethods are defined by two parameters. `}<inlineCode parentName="p">{`PaymentTypeGroup`}</inlineCode>{` and optionally `}<inlineCode parentName="p">{`PaymentType`}</inlineCode>{`:`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": "left"
          }}>{`PaymentTypeGroup`}</th>
          <th parentName="tr" {...{
            "align": "left"
          }}>{`PaymentType`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`PAYMENTCARD`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`VISA, MASTERCARD, AMEX`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`MOBILE`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`VIPPS`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`CASH`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`ECARD`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`AGENT`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`REMITTED`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`GIFTCARD`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`GIFTCARD`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`REQUISITION`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`INVOICE`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`COLLECTOR, COLLECTOR_B2B, XLEDGER`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`PAYPAL`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`PAYPAL`}</td>
        </tr>
      </tbody>
    </table>
    <h3 {...{
      "id": "payment-card"
    }}>{`Payment card`}</h3>
    <p><a parentName="p" {...{
        "href": "#table-of-contents"
      }}>{`(back to table of contents)`}</a></p>
    <p><a parentName="p" {...{
        "href": "#create-a-payment-transaction"
      }}>{`See above`}</a></p>
    <h4 {...{
      "id": "sequence-diagrams"
    }}>{`Sequence diagrams`}</h4>
    <p>{`We've created some sequence diagrams to illustrate the ideal way to create and handle payments.`}</p>
    <h5 {...{
      "id": "async-with-callback-and-auto-sale"
    }}>{`Async with callback and auto-sale`}</h5>
    <p>{`With this payment flow, you don't rely on the synchronized answer from the API-call. Instead, you'll listen and react to different events.`}</p>
    <p><img alt="Recommended payment flow for Nets" src={require("./async_with_callback_and_autosale.png")} /></p>
    <p>{`If you, as a client, should time out before the payment is completed, the following diagram illustrates how we want you to handle the payment flow.`}</p>
    <p><img alt="Recommended payment flow for Nets with timeout" src={require("./async_with_callback_and_autosale_client_timeout.png")} /></p>
    <h5 {...{
      "id": "async-without-auto-sale"
    }}>{`Async without auto-sale`}</h5>
    <p>{`If you want more control, it is possible to omit the autoSale from the transaction-call. This requires that the capture-call is performed.`}</p>
    <p><img alt="Async payment flow without auto sale" src={require("./async_with_callback_flow_no_autosale_common_payment_and_payment_transaction_call.png")} /></p>
    <p>{`We also recommend that if you time out before the payment is completed, that you try to refund the order when you receive events that indicate the payment has finished.`}</p>
    <h5 {...{
      "id": "standard-synchronized"
    }}>{`Standard synchronized`}</h5>
    <p>{`It is a possibility to do the whole process synchronously, but we do not recommend it.`}</p>
    <p><img alt="Synchronized payment flow" src={require("./synchronized_flow_no_autosale.png")} /></p>
    <p>{`Because this flow is synchronized, if you time out, you'll have no way of knowing if a cleanup is necessary (unless you also listen to events).`}</p>
    <h3 {...{
      "id": "vipps"
    }}>{`Vipps`}</h3>
    <p><a parentName="p" {...{
        "href": "#table-of-contents"
      }}>{`(back to table of contents)`}</a></p>
    <p>{`Using Vipps as payment method requires configuration of Vipps for your client. If this is not properly set up, please refer to the `}<a parentName="p" {...{
        "href": "#payment-methods"
      }}>{`Payment Methods`}</a>{` section before
continuing. Vipps can be used both for mobile and browser clients. Each client type needs to be configured as its own distribution channel so that our backend can configure each type
of client properly. This is important as the `}<inlineCode parentName="p">{`redirectUrl`}</inlineCode>{` will be different depending on which type of client you are using.`}</p>
    <p>{`Some of the set up will be documented by Vipps, and there are external links for this where appropriate.`}</p>
    <h4 {...{
      "id": "setup-vippsmt"
    }}>{`Setup VippsMT`}</h4>
    <p>{`To be able to test your integration fully, you have to set up the Vipps test app.
Documentation for this can be found on the `}<a parentName="p" {...{
        "href": "https://github.com/vippsas/vipps-developers#the-vipps-test-environment-mt"
      }}>{`Vipps test environment`}</a>{` section on Github.
We have some test users you can use in this environment:`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Mobile number`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`PIN`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`48059494`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`1236`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`48059568`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`1236`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`48059569`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`1236`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`48059570`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`1236`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`48059571`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`1236`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`48059572`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`1236`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`48059507`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`1236`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`48059506`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`1236`}</td>
        </tr>
      </tbody>
    </table>
    <h4 {...{
      "id": "create-payment-for-vipps"
    }}>{`Create payment for Vipps`}</h4>
    <p>{`Create a payment as you would usually do. The same `}<inlineCode parentName="p">{`orderId`}</inlineCode>{` is used here as in the general guide, which means that these steps should be switched out with the corresponding
steps in the guide above.`}</p>
    <p><inlineCode parentName="p">{`POST /sales/v1/payments`}</inlineCode></p>
    <ExpandablePanel title="Example request" mdxType="ExpandablePanel">
    <pre>{`{
  "totalAmount": "1064.00",
  "orderId": "K2131857",
  "orderVersion": 1,
  "currency": "NOK"
}`}</pre>
    </ExpandablePanel>
    <ExpandablePanel title="Example response" mdxType="ExpandablePanel">
    <pre>{`{
    "paymentId": 13417,
    "totalAmount": "1064.00",
    "currency": "NOK",
    "orderId": "K2131857",
    "orderVersion": 1,
    "organisationId": 1,
    "transactionHistory": [],
    "createdAt": "2019-01-28T09:26:43Z",
    "_links": {
        "addTransaction": {
            "href": "https://staging.entur.io/sales/v1/payments/13417/transactions"
        },
        "self": {
            "href": "https://staging.entur.io/sales/v1/payments/13417"
        }
    }
}`}</pre>
    </ExpandablePanel>
    <h4 {...{
      "id": "create-a-vipps-transaction"
    }}>{`Create a Vipps transaction`}</h4>
    <p>{`Create a payment transaction for the Vipps payment type to the specified payment. Notice the difference in the `}<inlineCode parentName="p">{`_links`}</inlineCode>{`-field from the general guide.`}</p>
    <p><inlineCode parentName="p">{`POST /sales/v1/payments/{paymentId}/transactions`}</inlineCode></p>
    <ExpandablePanel title="Example request" mdxType="ExpandablePanel">
    <pre>{`{
  "amount": "1064.00",
  "currency": "NOK",
  "paymentType": "VIPPS"
}`}</pre>
    </ExpandablePanel>
    <ExpandablePanel title="Example response" mdxType="ExpandablePanel">
    <pre>{`{
    "transactionId": 12733,
    "rrn": "000000012733",
    "currency": "NOK",
    "paymentType": "VIPPS",
    "paymentTypeGroup": "MOBILE",
    "summary": {
        "remainingAmountToCapture": "1064.00",
        "capturedAmount": "0.00",
        "remainingAmountToCredit": "0.00",
        "creditedAmount": "0.00"
    },
    "operationLog": [
        {
            "timestamp": "2019-01-28T09:43:44Z",
            "operation": "CREATE",
            "amount": "1064.00",
            "pos": "postman"
        }
    ],
    "amount": "1064.00",
    "status": "CREATED",
    "createdAt": "2019-01-28T09:43:44Z",
    "_links": {
        "app-claim": {
            "href": "https://staging.entur.io/v1/payments/13421/transactions/12733/app-claim"
        },
        "self": {
            "href": "https://staging.entur.io/sales/v1/payments/13421/transactions/12733"
        }
    }
}`}</pre>
    </ExpandablePanel>
    <h4 {...{
      "id": "create-an-app-claim"
    }}>{`Create an app claim`}</h4>
    <p>{`Instead of creating a virtual payment terminal, you now have to create an app-claim to be able to send the customer to the Vipps app.
The `}<inlineCode parentName="p">{`redirectUrl`}</inlineCode>{` format depends on the type of client you are using. For browser clients, this is a regular `}<inlineCode parentName="p">{`https`}</inlineCode>{` address, and for native app clients this should
be a deep link URL to your own app. More on this in the `}<a parentName="p" {...{
        "href": "#redirect-to-vipps"
      }}>{`next section`}</a>{`.`}</p>
    <p>{`Note: For mobile app clients, the phone number is not relevant, and will not be taken into consideration. The actual phone number logged into the Vipps app
on the customer's phone is always used. There does seem to be a bug in the API which means clients need to set this field to something, but it doesn't matter what.`}</p>
    <p><inlineCode parentName="p">{`POST /sales/v1/payments/{paymentId}/transactions/{transactionId}/app-claim`}</inlineCode></p>
    <ExpandablePanel title="Example request" mdxType="ExpandablePanel">
    <pre>{`{
    "phoneNumber": "48059494",
    "description": "Ticket from Entur AS",
    "redirectUrl": "my-app://PayOrder"
}`}</pre>
    </ExpandablePanel>
    <ExpandablePanel title="Example response" mdxType="ExpandablePanel">
    <pre>{`{
    "paymentId": 13421,
    "transactionId": 12733,
    "appClaimUrl": "vipps://?token=eyJraWQiOiJqd3RrZXkiLCJhbGciOiJSUzI1NiJ9.eyJzdWIiOiJmMDE0MmIxYy02YjIwLTQ1M2MtYTlmMS1lMWUwZGFiNjkzOTciLCJubyI6eyJoZWFkZXIiOiJCZXRhbCIsImNvbnRlbnQiOiI4OTkga3IgdGlsIFZZIEFwcCJ9LCJjdHgiOiI5NDU5NzI1Ny1lZGMwLTRhOWUtYTdlNC0yMzhjMjU1ZjcxOWMiLCJtZXJjaGFudFNlcmlhbE51bWJlciI6IjU0Mjk3MyIsImlzcyI6Imh0dHBzOlwvXC9WSVBQUy1QUk9ELUNPTi1BR0VOVC1pbGIudGVjaC0wMi5uZXRcL2RlZXBsaW5rLW9wZW5pZC1wcm92aWRlci1hcHBsaWNhdGlvblwvIiwiZW4iOnsiaGVhZGVyIjoiUGF5IiwiY29udGVudCI6Ijg5OSBrciB0byBWWSBBcHAifSwidHlwZSI6IlBBWU1FTlQiLCJ1dWlkIjoiZTJlODMxNTEtYTk4Mi00OGZhLWFkZWUtN2U1ZDZlMjc4YzdhIiwidXJsIjoiaHR0cHM6XC9cL2FwaS52aXBwcy5ub1wvbGFuZGluZ3BhZ2VcL2R3b1wvdjFcL2xhbmRpbmdwYWdlIiwiYXVkIjoiZjAxNDJiMWMtNmIyMC00NTNjLWE5ZjEtZTFlMGRhYjY5Mzk3IiwiYXpwIjoiZjAxNDJiMWMtNmIyMC00NTNjLWE5ZjEtZTFlMGRhYjY5Mzk3IiwiYXBwVHlwZSI6IkxBTkRJTkdQQUdFIiwiZXhwIjoxNTc0MTUyODIyLCJ0b2tlblR5cGUiOiJERUVQTElOSyIsImlhdCI6MTU3NDE1MjUyMiwiZmFsbGJhY2siOiJuc2I6XC9cL2Jvb2tpbmdcL29yZGVyLWNvbmZpcm1hdGlvblwvdmlwcHMiLCJqdGkiOiJkOWU2Y2RkYS1jMjcwLTQ5ZmQtYjBiOC1iZTZiNzE5MjE5MGYifQ.jLepja21nZtvNVdSDbWFLMVCtW4CweMbcKdg5eOTc0RIPtCfmgkkxDysLHiTGqDJZSkFgcsiWvYk3dO8FELcRf4n8be6jh6m6BHi2s0xojwGiGY7rO_VyiHYmAUcdX5vzfvwW55wqrQD_V0HgQCA_whwtxt4WlMomhRjvxfBMVkqELQOKZJSkdt2yiVRJP_dZXNOAWTX3YIMJ6nw1ZC5owU_gv_O8cJu9TxVo2X-n8BuMLroXY5IgKHBn0FaSGefs4J4Ffkhhw16OuiyilhzI1tstCklqSoKHm2EYoGATCBtCx1tT1TwE4EB7_gbEBw0CvnyhWMpOCv7GzGOMN2g0A",
    "_links": {
        "transaction": {
            "href": "https://staging.entur.io/v1/payments/13421/transactions/12733"
        }
    }
}`}</pre>
    </ExpandablePanel>
    <h4 {...{
      "id": "redirect-to-vipps"
    }}>{`Redirect to Vipps`}</h4>
    <p>{`For the actual integration between your client and the Vipps frontend, we point you to the Vipps documentation on
`}<a parentName="p" {...{
        "href": "https://github.com/vippsas/vipps-ecom-api/blob/master/vipps-ecom-api.md#app-integration"
      }}>{`App integration`}</a>{`. All backend related integration is fully handled by the Entur payment service.
According to the `}<a parentName="p" {...{
        "href": "https://github.com/vippsas/vipps-ecom-api/blob/master/vipps-ecom-api.md#please-note"
      }}>{`Vipps documentation`}</a>{`, it is not viable to rely on either the fallback nor callback URL, or session cookies in the browser for a successful payment.
After the customer has finished its interaction with the Vipps app, it is therefore recommended that the client either poll the payment transaction or consume PaymentEvents.
Out of these two alternatives we recommend consuming PaymentEvents. This is because consuming PaymentEvents handles exceptional cases with longer than expected response times in a more convenient manner than the other alternative.
How to perform these actions are described in the two following sections.`}</p>
    <h4 {...{
      "id": "consuming-paymentevents"
    }}>{`Consuming PaymentEvents`}</h4>
    <p>{`To be able to consume kafka events, please see `}<a parentName="p" {...{
        "href": "#accessing-the-external-kafka-cluster"
      }}>{`Accessing the external Kafka Cluster`}</a>{`. The relevant events for the use case are PaymentEvents, specifically PaymentEvents with the following types: PaymentTransactionCaptured, PaymentTransactionCancelled and PaymentTransactionRejected.
When a transaction is successfully captured, a PaymentTransactionCaptured-event will be produced. The clients consuming this event must verify that `}<inlineCode parentName="p">{`remainingAmountToCapture`}</inlineCode>{` in `}<inlineCode parentName="p">{`transactionSummary`}</inlineCode>{` is equal to `}<inlineCode parentName="p">{`"0.00"`}</inlineCode>{`.
If something unexpected happens during the capture-process, a PaymentTransactionCancelled-event or PaymentTransactionRejected-event will be produced.`}</p>
    <h4 {...{
      "id": "poll-payment-transaction"
    }}>{`Poll payment transaction`}</h4>
    <p>{`As the capture operation will be handled asynchronously between the Vipps and Entur backends, the client can poll the payment transaction for updates. There is a set time frame
where the transaction is live in the Vipps system, and the Entur payment service will keep track of the transaction status throughout this time frame. This means that all clients will have
to poll for updates for at least the same amount of time. The precise amount of time used by Vipps is described in detail in the
`}<a parentName="p" {...{
        "href": "https://github.com/vippsas/vipps-ecom-api/blob/master/vipps-ecom-api.md#timeouts"
      }}>{`Vipps documentation`}</a>{`.`}</p>
    <p>{`When polling the payment transaction, the client needs to check the `}<inlineCode parentName="p">{`operationLog`}</inlineCode>{` for a successful `}<inlineCode parentName="p">{`CAPTURE`}</inlineCode>{` operation for the same amount as the whole transaction. This is also visible
in the `}<inlineCode parentName="p">{`summary`}</inlineCode>{` as `}<inlineCode parentName="p">{`remainingAmountToCapture`}</inlineCode>{` should be equal to `}<inlineCode parentName="p">{`"0.00"`}</inlineCode>{`.`}</p>
    <p><inlineCode parentName="p">{`GET /sales/v1/payments/{paymentId}/transactions/{transactionId}`}</inlineCode></p>
    <ExpandablePanel title="Example response" mdxType="ExpandablePanel">
    <pre>{`{
    "paymentId": 13422,
    "totalAmount": "1064.00",
    "currency": "NOK",
    "orderId": "K2131857",
    "orderVersion": 1,
    "organisationId": 1,
    "transactionHistory": [
        {
            "transactionId": 12735,
            "rrn": "000000012735",
            "currency": "NOK",
            "paymentType": "VIPPS",
            "paymentTypeGroup": "MOBILE",
            "summary": {
                "remainingAmountToCapture": "0.00",
                "capturedAmount": "1064.00",
                "remainingAmountToCredit": "1064.00",
                "creditedAmount": "0.00"
            },
            "operationLog": [
                {
                    "timestamp": "2019-01-28T10:04:09Z",
                    "operation": "CAPTURE",
                    "amount": "1064.00",
                    "pos": "postman"
                },
                {
                    "timestamp": "2019-01-28T10:03:31Z",
                    "operation": "INITIATE",
                    "amount": "1064.00",
                    "pos": "postman"
                },
                {
                    "timestamp": "2019-01-28T10:03:28Z",
                    "operation": "CREATE",
                    "amount": "1064.00",
                    "pos": "postman"
                }
            ],
            "amount": "1064.00",
            "status": "CAPTURED",
            "createdAt": "2019-01-28T10:03:28Z",
            "updatedAt": "2019-01-28T10:03:31Z"
        }
    ],
    "createdAt": "2019-01-28T10:03:20Z",
    "_links": {
        "addTransaction": {
            "href": "https://staging.entur.io/v1/payments/13422/transactions"
        },
        "self": {
            "href": "https://staging.entur.io/sales/v1/payments/13422/transactions/12735"
        },
        "payment": {
            "href": "https://staging.entur.io/sales/v1/payments/13422"
        }
    }
}`}</pre>
    </ExpandablePanel>
    <h3 {...{
      "id": "paypal"
    }}>{`Paypal`}</h3>
    <p><a parentName="p" {...{
        "href": "#table-of-contents"
      }}>{`(back to table of contents)`}</a></p>
    <p><a parentName="p" {...{
        "href": "#create-a-payment-transaction"
      }}>{`See guide for payment card`}</a>{`, but use value `}<inlineCode parentName="p">{`PAYPAL`}</inlineCode>{` as paymentMethod.`}</p>
    <h3 {...{
      "id": "gift-card"
    }}>{`Gift card`}</h3>
    <p><a parentName="p" {...{
        "href": "#table-of-contents"
      }}>{`(back to table of contents)`}</a></p>
    <p>{`The sales API supports payment with gift cards generated by the Benefits API.
To pay with a gift card the customer needs to use the secret gift card code created by the Benefits API.`}</p>
    <p>{`Flow for paying with a gift card:`}</p>
    <ol>
      <li parentName="ol">
        <p parentName="li"><a parentName="p" {...{
            "href": "#create-payment"
          }}>{`Create payment`}</a></p>
      </li>
      <li parentName="ol">
        <p parentName="li"><a parentName="p" {...{
            "href": "#create-a-payment-transaction"
          }}>{`Create transaction`}</a>{` with paymentGroup `}<inlineCode parentName="p">{`GIFTCARD`}</inlineCode></p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Use `}<inlineCode parentName="p">{`POST /sales/v1/payments/{paymentId}/transactions/{transactionId}/gift-card-claim`}</inlineCode>{` to capture the payment`}</p>
        <ExpandablePanel title="Example request body" mdxType="ExpandablePanel">
          <pre>{`{
    "giftCardCode" : "1234567891"
}`}</pre>
        </ExpandablePanel>
      </li>
    </ol>
    <p>{`To get information about the gift card, use the `}<a parentName="p" {...{
        "href": "/pages-benefits-docs-benefits-api"
      }}>{`Benefits API`}</a></p>
    <p>{`This payment method is refundable by using `}<a parentName="p" {...{
        "href": "#refunding"
      }}>{`refund`}</a>{`.`}</p>
    <BannerAlertBox variant="warning" mdxType="BannerAlertBox">
    However, the refunded money will be inserted into a new gift card with a new
    code printed on the receipt.
    </BannerAlertBox>
    <h3 {...{
      "id": "invoice"
    }}>{`Invoice`}</h3>
    <p><a parentName="p" {...{
        "href": "#table-of-contents"
      }}>{`(back to table of contents)`}</a></p>
    <p>{`Invoice of type Collector is offered through Netaxept and requires a manual step where the user fills in contact information.
The contact information form is pre-filled with the information registered on the customers' profile.
The minimum required information registered on the profile is an email address.`}</p>
    <p>{`Flow for paying with invoice type Collector:`}</p>
    <ol>
      <li parentName="ol"><a parentName="li" {...{
          "href": "#create-payment"
        }}>{`Create payment`}</a></li>
      <li parentName="ol"><a parentName="li" {...{
          "href": "#create-a-payment-transaction"
        }}>{`Create transaction`}</a>{` with paymentGroup `}<inlineCode parentName="li">{`INVOICE`}</inlineCode>{` and paymentType `}<inlineCode parentName="li">{`COLLECTOR`}</inlineCode></li>
      <li parentName="ol">{`Use `}<inlineCode parentName="li">{`POST /sales/v1/payments/{paymentId}/transactions/{transactionId}/invoice`}</inlineCode>{` to crate a terminal in Netaxept`}</li>
      <li parentName="ol"><a parentName="li" {...{
          "href": "#capture-transaction"
        }}>{`Capture Transaction`}</a></li>
    </ol>
    <p>{`Example request body:`}</p>
    <p><inlineCode parentName="p">{`POST /sales/v1/payments/{paymentId}/transactions/{transactionId}/invoice`}</inlineCode></p>
    <pre><code parentName="pre" {...{}}>{`{
  "redirectUrl" : "https://www.entur.org/",
  "languageCode" : "NOB"
}
`}</code></pre>
    <p>{`This payment method is refundable by using `}<a parentName="p" {...{
        "href": "#refunding"
      }}>{`refund`}</a>{`.`}</p>
    <h3 {...{
      "id": "imported-payment-methods"
    }}>{`Imported payment methods`}</h3>
    <p><a parentName="p" {...{
        "href": "#table-of-contents"
      }}>{`(back to table of contents)`}</a></p>
    <p>{`Import payments follow the same flow but require different additional fields when executing an import request. These are the steps needed to make an import payment:`}</p>
    <ol>
      <li parentName="ol"><a parentName="li" {...{
          "href": "#create-payment"
        }}>{`Create payment`}</a></li>
      <li parentName="ol"><a parentName="li" {...{
          "href": "#create-a-payment-transaction"
        }}>{`Create transaction`}</a></li>
      <li parentName="ol">{`Import payment, see the following sections for how to.`}</li>
    </ol>
    <h4 {...{
      "id": "payment-card-1"
    }}>{`Payment card`}</h4>
    <p>{`There are three fields required for importing a payment card payment:`}</p>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`paymentType`}</inlineCode>{`: Name of the specific payment type used`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`rrn`}</inlineCode>{`: Reconciliation reference number used to track a transaction through different economy systems`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`terminalId`}</inlineCode>{`: Unique identifier for a physical terminal`}</li>
    </ul>
    <p>{`Example request for import:`}</p>
    <p><inlineCode parentName="p">{`POST /sales/v1/payments/{paymentId}/transactions/{transactionId}/import`}</inlineCode></p>
    <pre><code parentName="pre" {...{}}>{`{
  "transactionConfirmedAt": "2018-03-07T12:20:46Z",
  "paymentType": "VISA",
  "rrn": "4",
  "terminalId" : "578000"
  "transactionData": {
    "additionalProp": "testProp"
  }
}
`}</code></pre>
    <h4 {...{
      "id": "cash"
    }}>{`Cash`}</h4>
    <p>{`Example request for import:`}</p>
    <p><inlineCode parentName="p">{`POST /sales/v1/payments/{paymentId}/transactions/{transactionId}/import`}</inlineCode></p>
    <pre><code parentName="pre" {...{}}>{`{
  "transactionConfirmedAt": "2018-03-07T12:20:46Z",
  "transactionData": {
    "additionalProp": "testProp"
  }
}
`}</code></pre>
    <h4 {...{
      "id": "e-card"
    }}>{`E-card`}</h4>
    <p>{`There are three fields required for importing an e-card payment:`}</p>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`mediaSerialNumberId`}</inlineCode>{`: A 10 digit e-Card identifier`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`companyId`}</inlineCode>{`: Issuer OIO id`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`envNetworkId`}</inlineCode>{`: County code for issuer`}</li>
    </ul>
    <p>{`Example request for import:`}</p>
    <p><inlineCode parentName="p">{`POST /sales/v1/payments/{paymentId}/transactions/{transactionId}/import`}</inlineCode></p>
    <pre><code parentName="pre" {...{}}>{`{
  "transactionConfirmedAt": "2018-03-07T12:20:46Z",
  "mediaSerialNumberId": "0067340023",
  "companyId": "4",
  "envNetworkId" : "578000"
  "transactionData": {
    "additionalProp": "testProp"
  }
}
`}</code></pre>
    <h4 {...{
      "id": "agent"
    }}>{`Agent`}</h4>
    <p>{`Example request for import:`}</p>
    <p><inlineCode parentName="p">{`POST /sales/v1/payments/{paymentId}/transactions/{transactionId}/import`}</inlineCode></p>
    <pre><code parentName="pre" {...{}}>{`{
  "transactionConfirmedAt": "2018-03-07T12:20:46Z",
  "transactionData": {
    "additionalProp": "testProp"
  }
}
`}</code></pre>
    <h4 {...{
      "id": "remitted"
    }}>{`Remitted`}</h4>
    <p>{`Example request for import:`}</p>
    <p><inlineCode parentName="p">{`POST /sales/v1/payments/{paymentId}/transactions/{transactionId}/import`}</inlineCode></p>
    <pre><code parentName="pre" {...{}}>{`{
  "transactionConfirmedAt": "2018-03-07T12:20:46Z",
  "transactionData": {
    "additionalProp": "testProp"
  }
}
`}</code></pre>
    <h3 {...{
      "id": "fees"
    }}>{`Fees`}</h3>
    <p><a parentName="p" {...{
        "href": "#table-of-contents"
      }}>{`(back to table of contents)`}</a></p>
    <p>{`Some Payment Methods have fees related to them. The payment total amount will be automatically updated with the fee amount once the transaction is added (after a `}<inlineCode parentName="p">{`POST /sales/v1/payments/{paymentId}/transactions/`}</inlineCode>{`).
To see whether a Payment Method has a fee, look for the 'paymentTypeFee' in the PaymentMethod response. The fees are also specified in the payment transaction response.`}</p>
    <h2 {...{
      "id": "accessing-the-external-kafka-cluster"
    }}>{`Accessing the external Kafka Cluster`}</h2>
    <p><a parentName="p" {...{
        "href": "#table-of-contents"
      }}>{`(back to table of contents)`}</a></p>
    <h3 {...{
      "id": "prerequisites"
    }}>{`Prerequisites`}</h3>
    <p><a parentName="p" {...{
        "href": "#table-of-contents"
      }}>{`(back to table of contents)`}</a></p>
    <p>{`In order to access the external Kafka Cluster the following things need to be prepared:`}</p>
    <ul>
      <li parentName="ul">{`A username and a password for the Kafka cluster`}</li>
      <li parentName="ul">{`Organization-specific topics for your organization needs to be created`}</li>
    </ul>
    <p>{`If you don't have these things in order, please contact your Partner Assistant in order to obtain access. You will not be able to connect and test your implementation without this.`}</p>
    <p>{`Before starting implementation we recommend that you familiarize yourselves with the following documentation:`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://docs.confluent.io/current/clients/index.html"
        }}>{`https://docs.confluent.io/current/clients/index.html`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://docs.confluent.io/4.1.2/schema-registry/docs/api.html"
        }}>{`https://docs.confluent.io/4.1.2/schema-registry/docs/api.html`}</a></li>
    </ul>
    <h3 {...{
      "id": "quickstart"
    }}>{`Quickstart`}</h3>
    <p><a parentName="p" {...{
        "href": "#table-of-contents"
      }}>{`(back to table of contents)`}</a></p>
    <p>{`As the Confluent documentation shows, there isn't that much configuration required to get started with a Kafka client. We have highlighted the few configuration properties required to be able to connect and communicate with Entur's external Kafka cluster.`}</p>
    <h4 {...{
      "id": "bootstrapservers"
    }}>{`bootstrap.servers`}</h4>
    <p>{`Use the following URLs to connect to the clusters:`}</p>
    <p>{`Staging:`}</p>
    <pre><code parentName="pre" {...{}}>{`bootstrap.test-ext.kafka.entur.io:9095
`}</code></pre>
    <p>{`Production`}</p>
    <pre><code parentName="pre" {...{}}>{`bootstrap.prod-ext.kafka.entur.io:9095
`}</code></pre>
    <p>{`Note: The standard client port is not open, as we require every client to use a secure connection via SSL and SASL – see Security for more information.`}</p>
    <h4 {...{
      "id": "groupid"
    }}>{`group.id`}</h4>
    <p>{`Collective identification of all clients that belong to the same application. All instances of the same application must have the same group id. This means it should be set specifically and preferably to a descriptive name to make it easier to identify which user should have access to each group.`}</p>
    <h4 {...{
      "id": "schemaregistryurl"
    }}>{`schema.registry.url`}</h4>
    <p>{`URL used to connect to the Avro schema storage.`}</p>
    <p>{`Staging:`}</p>
    <pre><code parentName="pre" {...{}}>{`http://schema-registry.test-ext.kafka.entur.io:8001
`}</code></pre>
    <p>{`Production:`}</p>
    <pre><code parentName="pre" {...{}}>{`http://schema-registry.prod-ext.kafka.entur.io:8001
`}</code></pre>
    <h4 {...{
      "id": "health-checks"
    }}>{`Health checks`}</h4>
    <p>{`We provide a health check topic you can use to test client configuration where random test data is published without a schema – see Testing for more information.`}</p>
    <h3 {...{
      "id": "security"
    }}>{`Security`}</h3>
    <p><a parentName="p" {...{
        "href": "#table-of-contents"
      }}>{`(back to table of contents)`}</a></p>
    <p>{`Our Kafka cluster is setup with in-flight message encryption, using one-way SSL for all communication. The certificates are issued by
Let's Encrypt, and should be trusted by most JVMs / operating systems.`}</p>
    <ol>
      <li parentName="ol"><strong parentName="li">{`Client security configuration:`}</strong></li>
    </ol>
    <pre><code parentName="pre" {...{}}>{`security.protocol=SASL_SSL
sasl.mechanism=SCRAM-SHA-512
sasl.jaas.config=org.apache.kafka.common.security.scram.ScramLoginModule required \\
     username="username" \\
     password="$USER_PASSWORD";
`}</code></pre>
    <p>{`Example Java code with staging addresses (change to production from the list above to connect to the production environment):`}</p>
    <pre><code parentName="pre" {...{}}>{`Properties properties = new Properties();
properties.put(ProducerConfig.BOOTSTRAP_SERVERS_CONFIG, "bootstrap.test-ext.kafka.entur.io:9095");
properties.put(ConsumerConfig.GROUP_ID_CONFIG, "my-application-name");
properties.put(ConsumerConfig.ENABLE_AUTO_COMMIT_CONFIG, false);
properties.put(ConsumerConfig.AUTO_OFFSET_RESET_CONFIG, "earliest");
properties.put(ConsumerConfig.KEY_DESERIALIZER_CLASS_CONFIG, KafkaAvroDeserializer.class);
properties.put(ConsumerConfig.VALUE_DESERIALIZER_CLASS_CONFIG, KafkaAvroDeserializer.class);
properties.put("specific.avro.reader", true);
properties.put("schema.registry.url", "http://schema-registry.test-ext.kafka.entur.io:8001");

// Security
properties.put(CommonClientConfigs.SECURITY_PROTOCOL_CONFIG, "SASL_SSL");

properties.put(SaslConfigs.SASL_MECHANISM, "SCRAM-SHA-512");
properties.put(SaslConfigs.SASL_JAAS_CONFIG, String.format("org.apache.kafka.common.security.scram.ScramLoginModule required\\nusername=\\"%s\\"\\npassword=\\"%s\\";", config.getSasl().get("username"), config.getSasl().get("password")));

producer = new KafkaConsumer<>(properties);
`}</code></pre>
    <p>{`Before this client can start consuming from Kafka, the user credentials need to be registered. All access control is based on SASL (Simple Authentication and Security Layer) using SCRAM (Salted Challenge and Response Authentication Mechanism). Upon registration with our Kafka cluster, each user will be given a username and a password, which should not be shared with anyone else. This user will be given consumer rights to the needed topics for your organization and every consumer group ( group.id ) used to read from these topics. By default, every resource in the cluster will deny everyone unless the provided credentials match the ACL for that resource.`}</p>
    <h3 {...{
      "id": "testing-client-configuration"
    }}>{`Testing Client Configuration`}</h3>
    <p><a parentName="p" {...{
        "href": "#table-of-contents"
      }}>{`(back to table of contents)`}</a></p>
    <p>{`Before setting up a connection to the schema registry and downloading the appropriate Avro schema etc., there is a health check topic where random test data is published. Every user registered on our Kafka platform should have access to consume messages from this topic, with a provided consumer group, as long as the necessary security configuration mentioned above is set up correctly. The topic can be used as a simple way to get started with Kafka and to test that client configuration meets the requirements without having to think about any business logic on the consumer side. Below is all the information you need to get started:`}</p>
    <ol>
      <li parentName="ol">{`topic name: healthcheck-staging`}</li>
      <li parentName="ol">{`consumer group: `}<inlineCode parentName="li">{`<username>-consumer-staging`}</inlineCode></li>
    </ol>
    <p>{`Testing access to topics can also be done, without having to implement your own Kafka clients, by using a tool called kafkacat. Information about installing and using this tool can be found here: `}<a parentName="p" {...{
        "href": "https://github.com/edenhill/kafkacat"
      }}>{`https://github.com/edenhill/kafkacat`}</a>{`
An example command using all necessary security configurations for the staging cluster:`}</p>
    <pre><code parentName="pre" {...{}}>{`BOOTSTRAP_SERVERS=bootstrap.test-ext.kafka.entur.io:9095
TOPIC=sales-transaction-summary-staging-20
SECURITY_PROTOCOL=SASL_SSL
SASL_MECHANISMS=SCRAM-SHA-512
SASL_USERNAME=<your provided username>
SASL_PASSWORD=<password>
kafkacat -C -b $BOOTSTRAP_SERVERS -t $TOPIC -X security.protocol=$SECURITY_PROTOCOL -o beginning -X sasl.mechanisms=$SASL_MECHANISMS -X sasl.username=$SASL_USERNAME -X sasl.password=$SASL_PASSWORD
`}</code></pre>
    <p>{`Please note that records will be consumed with this tool, which means that you should NOT use the same consumer group as you plan to use in your actual application.`}</p>
    <h2 {...{
      "id": "topic-descriptions"
    }}>{`Topic Descriptions`}</h2>
    <p><a parentName="p" {...{
        "href": "#table-of-contents"
      }}>{`(back to table of contents)`}</a></p>
    <h3 {...{
      "id": "naming-conventions"
    }}>{`Naming conventions`}</h3>
    <p><a parentName="p" {...{
        "href": "#table-of-contents"
      }}>{`(back to table of contents)`}</a></p>
    <p>{`All data on the external cluster is segregated on topics that only 1 organization has access to. As such, your organization can only access data applicable to your organization id, and no other organization will be able to read this data. Each topic will have an organization id in the name that matches the id of the data owner's organization. This id is appended to every topic on the cluster and drives the access rights to the topic.`}</p>
    <p>{`All topic names (except the health check topic) follow the same convention:`}</p>
    <pre><code parentName="pre" {...{}}>{`<content description>-<environment>-<organisationId>
Example: payments-staging-1
`}</code></pre>
    <h3 {...{
      "id": "topics"
    }}>{`Topics`}</h3>
    <p><a parentName="p" {...{
        "href": "#table-of-contents"
      }}>{`(back to table of contents)`}</a></p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Name`}</th>
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Description`}</th>
          <th parentName="tr" {...{
            "align": "right"
          }}>{`#partitions`}</th>
          <th parentName="tr" {...{
            "align": "right"
          }}>{`retention`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`healthcheck-staging`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Health check topic`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`6`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`1 h`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`inventory-reservations-staging-<orgid>`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`All reservations of stock to be read by the organization`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`9`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`7 d`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`inventory-seat-reservations-staging-<orgid>`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`All seat reservations performed in Seating to be read by the organization`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`9`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`7 d`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`payments-staging-<orgid>`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Completed payments with payment transactions for the organization`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`15`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`28 d`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`payment-events-staging-<orgid>`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Events that happened to a payment. E.g. created, captured, rejected`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`15`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`28 d`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`credits-staging-<orgid>`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Completed credits with credit transactions for the organization`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`15`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`28 d`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`ticket-distribution-group-events-staging-<orgid>`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Events that happened to a ticket distribution group to be read by the organization. E.g. created, picked up, distributed, cancelled`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`15`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`28 d`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`sales-transaction-summary-staging-<orgid>`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Confirmed sales including all affected order line versions where the organization is either the owner of the product or the sales are completed in an organization-owned channel`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`15`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`28 d`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`given-consent-changed-staging-<orgid>`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`All consents that have been changed by users from this organization.`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`9`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`compacted`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`customer-changed-staging-<orgid>`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`All customers that have been changed for this organization.`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`9`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`compacted`}</td>
        </tr>
      </tbody>
    </table>
    <h3 {...{
      "id": "schema-registry"
    }}>{`Schema Registry`}</h3>
    <p><a parentName="p" {...{
        "href": "#table-of-contents"
      }}>{`(back to table of contents)`}</a></p>
    <p>{`All schemas can be found using the REST API for the Schema Registry. This registry will always be versioned and up-to-date.`}</p>
    <BannerAlertBox variant="info" mdxType="BannerAlertBox">For security reasons, we only allow READ access to external clients. This means that HTTP GET methods are the only valid requests from the schema registry REST API documentation.</BannerAlertBox>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`docker run --rm -p 8000:8000 -e "SCHEMAREGISTRY_URL=http://schema-registry.test-ext.kafka.entur.io:8001" -e "PROXY=true" landoop/schema-registry-ui
http://localhost:8000
`}</code></pre>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`docker run --rm -p 8000:8000 -e "SCHEMAREGISTRY_URL=http://schema-registry.prod-ext.kafka.entur.io:8001" -e "PROXY=true" landoop/schema-registry-ui
http://localhost:8000
`}</code></pre>
    <h2 {...{
      "id": "add-recurring-payment"
    }}>{`Add Recurring Payment`}</h2>
    <p><a parentName="p" {...{
        "href": "#table-of-contents"
      }}>{`(back to table of contents)`}</a></p>
    <p>{`This guide shows you how to create a recurring payment, create a terminal to input card details for the recurring payment, and authorize the card details that that were used as input when creating the terminal.`}</p>
    <p>{`A recurring payment is a payment that is repeated. By creating a recurring payment, the payment details (credit/debit card details) are saved. This makes it easier to perform a transaction later because it is not necessary to input card details.`}</p>
    <h3 {...{
      "id": "create-recurring-payment"
    }}>{`Create Recurring Payment`}</h3>
    <p><a parentName="p" {...{
        "href": "#table-of-contents"
      }}>{`(back to table of contents)`}</a></p>
    <p><inlineCode parentName="p">{`POST /sales/v1/recurring-payments`}</inlineCode></p>
    <p>{`Creates a new recurring payment with status `}<inlineCode parentName="p">{`CREATED`}</inlineCode>{` and couples it to the `}<inlineCode parentName="p">{`customerNumber`}</inlineCode>{` sent in the request.`}</p>
    <ExpandablePanel title="Example request" mdxType="ExpandablePanel">
    <pre>
        {`{
    "customerNumber": 7654321,
    "isPrimary": true,
    "nickname": "Example Bank Mastercard"
}`}
    </pre>
    </ExpandablePanel>
    <ExpandablePanel title="Example response" mdxType="ExpandablePanel">
    <pre>
        {`{
    "recurringPaymentId": 1234,
    "recurringStatus": "CREATED",
    "nickname": "Example Bank Mastercard",
    "primary": true,
    "_links": {
        "terminal": {
            "href": "http://payment-staging.devstage.entur.io/sales/v1/recurring-payments/1234/terminal"
        }
    }
}`}
    </pre>
    </ExpandablePanel>
    <h3 {...{
      "id": "create-terminal-1"
    }}>{`Create Terminal`}</h3>
    <p><a parentName="p" {...{
        "href": "#table-of-contents"
      }}>{`(back to table of contents)`}</a></p>
    <p><inlineCode parentName="p">{`POST /sales/v1/recurring-payments/{recurringPaymentId}/terminal`}</inlineCode></p>
    <p>{`Registers the transaction with Nets and returns the location of the payment terminal where the user will provide card information. The terminalUri in the response is where the user has to input card information.
Use the `}<inlineCode parentName="p">{`recurringPaymentId`}</inlineCode>{` from the previous request.`}</p>
    <ExpandablePanel title="Example request" mdxType="ExpandablePanel">
    <pre>
        {`{
    "callbackUrl": "http://entur.org",
    "customerNumber": "7654321",
    "redirectUrl": "http://www.entur.org",
    "storePayment": true,
    "terminalLanguage": "no_NO"
}`}
    </pre>
    </ExpandablePanel>
    <ExpandablePanel title="Example response" mdxType="ExpandablePanel">
    <pre>{`{
   "recurringPaymentId": 1234,
   "terminalUri": "https://test.epayment.nets.eu/Terminal/default.aspx?merchantId=12002713&transactionId=9ea2dd6c4c3d4974ae2fcb666c16c897",
   "_links": {
       "authorize": {
           "href": "http://payment-staging.devstage.entur.io/sales/v1/recurring-payments/1234/authorize"
       }
   }
}`}</pre>
    </ExpandablePanel>
    <h3 {...{
      "id": "authorize-recurring-terminal"
    }}>{`Authorize Recurring Terminal`}</h3>
    <p><a parentName="p" {...{
        "href": "#table-of-contents"
      }}>{`(back to table of contents)`}</a></p>
    <p><inlineCode parentName="p">{`PUT /sales/v1/recurring-payments/{recurringPaymentId}/authorize`}</inlineCode></p>
    <p>{`Authorize the card details provided by the customers in the terminal were valid and accepted by Nets and by calling Nets with a process call.
Use the `}<inlineCode parentName="p">{`recurringPaymentId`}</inlineCode>{` from the previous request.`}</p>
    <ExpandablePanel title="Example request" mdxType="ExpandablePanel">
    <pre>{`{
    "customerNumber": 7654321,
    "isPrimary": true,
    "nickname": "Example Bank Mastercard"
}`}</pre>
    </ExpandablePanel>
    <ExpandablePanel title="Example response" mdxType="ExpandablePanel">
    <pre>{`{
   "recurringPaymentId": 1234,
   "recurringStatus": "ACTIVE",
   "nickname": "Example Bank Mastercard",
   "expiresAt": "2022-09-09T10:00:00Z",
   "maskedPan": "0004",
   "paymentType": "VISA",
   "cardExpiresAt": "2025-01-01T00:00:00Z",
   "primary": true,
   "_links": {
       "update": {
           "href": "http://payment-staging.devstage.entur.io/sales/v1/recurring-payments/1234"
       },
       "cancel": {
           "href": "http://payment-staging.devstage.entur.io/sales/v1/recurring-payments/1234"
       }
   }
}`}</pre>
    </ExpandablePanel>
    <h2 {...{
      "id": "use-recurring-payments"
    }}>{`Use Recurring Payments`}</h2>
    <p><a parentName="p" {...{
        "href": "#table-of-contents"
      }}>{`(back to table of contents)`}</a></p>
    <p>{`When creating a recurring payment, the payment details (credit/debit card details) are saved for a customer.`}</p>
    <p>{`To use a recurring payment, the following must be done:`}</p>
    <ol>
      <li parentName="ol">
        <p parentName="li">{`A payment must be created: `}<a parentName="p" {...{
            "href": "#create-payment"
          }}>{`create payment`}</a></p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Get all recurring payments for a customer: `}<a parentName="p" {...{
            "href": "#get-recurring-payments-for-customer"
          }}>{`get recurring payments for customer`}</a></p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Add a payment transaction to the payment: `}<a parentName="p" {...{
            "href": "#create-a-payment-transaction"
          }}>{`create a payment transaction`}</a>{` with a request body that contains a reference to one of the customers recurring payments. The field that has to be set in the request is `}<inlineCode parentName="p">{`recurringPaymentId`}</inlineCode>{`. See the example request for recurring payment transactions below.`}</p>
        <ExpandablePanel title="Example request for recurring payment transaction" mdxType="ExpandablePanel">
          <pre>{`{
"amount": "450.00",
"currency": "NOK",
"isImport": false,
"paymentType": "VISA",
"paymentTypeGroup": "PAYMENTCARD",
"recurringPaymentId": 111,
"with3DSecure": true
}`}</pre>
        </ExpandablePanel>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Create a terminal: `}<a parentName="p" {...{
            "href": "#create-terminal"
          }}>{`create a terminal`}</a></p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Capture transaction: `}<a parentName="p" {...{
            "href": "#capture-transaction"
          }}>{`capture transaction`}</a></p>
      </li>
    </ol>
    <h3 {...{
      "id": "get-recurring-payments-for-customer"
    }}>{`Get recurring payments for customer`}</h3>
    <p><a parentName="p" {...{
        "href": "#table-of-contents"
      }}>{`(back to table of contents)`}</a></p>
    <p><inlineCode parentName="p">{`GET /sales/v1/recurring-payments?customerNumber={customerNumber}&includeExpired={includeExpired}`}</inlineCode></p>
    <p>{`Gets all saved recurring payment (credit/debit cards) for a customer`}</p>
    <ExpandablePanel title="Example response" mdxType="ExpandablePanel">
    <pre>
        {`[
  {
    "cardExpiresAt": "2020-11-05T07:20:48.013Z",
    "expiresAt": "2020-11-05T07:20:48.013Z",
    "links": {
      "empty": true
    },
    "maskedPan": "4925 **** **** 0004",
    "nickname": "Bottomless mastercard",
    "paymentType": "AMEX",
    "primary": true,
    "recurringPaymentId": 111,
    "recurringStatus": "ACTIVE"
  }
]`}
    </pre>
    </ExpandablePanel>
    <h2 {...{
      "id": "strong-customer-authentication-exemption"
    }}>{`Strong Customer Authentication Exemption`}</h2>
    <p><a parentName="p" {...{
        "href": "#table-of-contents"
      }}>{`(back to table of contents)`}</a></p>
    <p>{`Strong Customer Authentication (SCA) is required for transactions made online in the European Economic Area (EEA).
However, a transaction may be exempt from SCA under certain circumstances.
Currently, Entur only supports exempting low value transaction; i.e., transaction with a value of less than 30 euro.`}</p>
    <p>{`Follow these steps to try to exempt a transaction from SCA:`}</p>
    <BannerAlertBox variant="warning" mdxType="BannerAlertBox">Before using SCA exemption, the client should first understand the extra risk it is accepting, e.g., related to fraud.</BannerAlertBox>
    <br />
    <ol>
      <li parentName="ol">
        <p parentName="li">{`Create a payment as usual: `}<a parentName="p" {...{
            "href": "#create-payment"
          }}>{`Create payment`}</a></p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Create a payment transaction as usual, however this time include the field `}<inlineCode parentName="p">{`scaExemption`}</inlineCode>{` with the value of `}<inlineCode parentName="p">{`LOW_AMOUNT`}</inlineCode>{`, it is the only currently supported exemption.`}</p>
        <BannerAlertBox variant="info" mdxType="BannerAlertBox">The LOW_AMOUNT exemption will only be honoured when the total transaction amount is under 30 euro (roughly 300 NOK). If the total amount is greater than 30 euros SCA exemption will not be attempted.</BannerAlertBox>
        <br />
        <p parentName="li"><inlineCode parentName="p">{`POST /sales/v1/payments/{paymentId}/transactions`}</inlineCode></p>
        <ExpandablePanel title="Example request: Low amount" mdxType="ExpandablePanel">
          <pre>{`{
"amount": "81.00",
"currency": "NOK",
"paymentType": "VISA",
"scaExemption": "LOW_AMOUNT"
}`}</pre>
        </ExpandablePanel>
        <p parentName="li">{`An example response can be found in the `}<a parentName="p" {...{
            "href": "#create-a-payment-transaction"
          }}>{`Create a Payment Transaction Guide`}</a>{`.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Create a terminal and redirect the customer to the terminal url.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`The rest of the payment-process is the same if the SCA exemption request succeeds.
However, the SCA exemption request `}<em parentName="p">{`will`}</em>{` eventually fail.
When this happens the capturePayment-call will return with http status `}<inlineCode parentName="p">{`409 - CONFLICT`}</inlineCode>{` and the response body will contain an `}<a parentName="p" {...{
            "href": "#ErrorReason"
          }}>{`errorReason`}</a>{` with the value `}<inlineCode parentName="p">{`SOFT_DECLINE`}</inlineCode>{`.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`The correct way for a client to handle `}<inlineCode parentName="p">{`SOFT_DECLINE`}</inlineCode>{` is to redirect the customer to `}<strong parentName="p">{`same`}</strong>{` terminal url created in step 3.
This will trigger SCA.`}</p>
      </li>
    </ol>
    <h2 {...{
      "id": "error-handling"
    }}>{`Error handling`}</h2>
    <p><a parentName="p" {...{
        "href": "#table-of-contents"
      }}>{`(back to table of contents)`}</a></p>
    <h3 {...{
      "id": "payment"
    }}>{`Payment`}</h3>
    <p><a parentName="p" {...{
        "href": "#table-of-contents"
      }}>{`(back to table of contents)`}</a></p>
    <p>{`When interacting with the Payment API, errors might happen either in the application itself, or upstream. In most of these cases the Payment API will return either a PaymentError or a ValidationError`}</p>
    <h4 {...{
      "id": "paymenterror"
    }}>{`PaymentError`}</h4>
    <p>{`A PaymentError contains the following values:`}</p>
    <ul>
      <li parentName="ul">{`error`}</li>
      <li parentName="ul">{`exception`}</li>
      <li parentName="ul">{`message`}</li>
      <li parentName="ul">{`path`}</li>
      <li parentName="ul">{`status`}</li>
      <li parentName="ul">{`timestamp`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#ErrorReason"
        }}>{`errorReason`}</a></li>
    </ul>
    <p>{`These values can be used to determine what went wrong, and then react accordingly.`}</p>
    <h4 {...{
      "id": "validationerror"
    }}>{`ValidationError`}</h4>
    <p>{`A validation error contains exactly the same values as a PaymentError, and a list of errors.`}</p>
    <h4 {...{
      "id": "errorreason"
    }}>{`ErrorReason`}</h4>
    <p>{`The `}<inlineCode parentName="p">{`ErrorReason`}</inlineCode>{`-value exists to make it easier for clients to react to different errors.
More might be added in the future, so your code must handle unknown error reasons.`}</p>
    <p>{`The following `}<inlineCode parentName="p">{`ErrorReason`}</inlineCode>{`s exist as of writing:`}</p>
    <h5 {...{
      "id": "soft_decline"
    }}>{`SOFT_DECLINE`}</h5>
    <p>{`When the `}<inlineCode parentName="p">{`ErrorReason`}</inlineCode>{`-value is `}<inlineCode parentName="p">{`SOFT DECLINE`}</inlineCode>{`, a transaction attempted with the Strong Customer Authentication (SCA) exception is declined.
This means the customer's bank did not allow the transaction to be captured without SCA.`}</p>
    <p>{`The correct way to react to this error is described in `}<a parentName="p" {...{
        "href": "#strong-customer-authentication-exemption"
      }}>{`Strong Customer Authentication Exemption`}</a>{`.`}</p>
    <h5 {...{
      "id": "refused_by_issuer"
    }}>{`REFUSED_BY_ISSUER`}</h5>
    <p>{`The payment was denied by the customer's bank or payment method service provider.
One possible response is to suggest that the consumer contact their bank or payment method service provider to resolve the issue.`}</p>
    <h5 {...{
      "id": "resource_busy"
    }}>{`RESOURCE_BUSY`}</h5>
    <p>{`Another process is currently utilising the requested resource. As of now, this only applies to Penalty Fare bills.
Whenever this happens, wait a few seconds before making the same API request again.`}</p>
    <h5 {...{
      "id": "user_error"
    }}>{`USER_ERROR`}</h5>
    <p>{`The `}<inlineCode parentName="p">{`USER_ERROR`}</inlineCode>{` error reason is an extension of the `}<inlineCode parentName="p">{`REFUSED_BY_ISSUER`}</inlineCode>{` error reason.
However, when this reason is given it is because the user was the source of the error.`}</p>
    <p>{`Typical cases which might result in this error reasons are:`}</p>
    <ul>
      <li parentName="ul">{`Insufficient funds`}</li>
      <li parentName="ul">{`Invalid card number`}</li>
      <li parentName="ul">{`Invalid CVV/CVN`}</li>
    </ul>
    <p>{`The intended response to this error reason is to advise the customer to double-check their submitted data and to ensure that sufficient funds are available to cover the transaction.
If all else fails, the fallback option is to ask the customer to contact their bank or payment method service provider if the problem persists.`}</p>
    <h4 {...{
      "id": "timeouts"
    }}>{`Timeouts`}</h4>
    <p><a parentName="p" {...{
        "href": "#table-of-contents"
      }}>{`(back to table of contents)`}</a></p>
    <h5 {...{
      "id": "capture-payment-client-timeout"
    }}>{`Capture payment client timeout`}</h5>
    <p>{`If a client has a self-declared timeout value lower than 60 seconds, the client might miss information about the transaction being captured.
Depending on how the client handles these situations, this might cause the customer to purchase the same travel multiple times when they only intend to purchase the travel once.`}</p>
    <p>{`So how should a client ideally handle the capture-payment-step when a self-induced timeout occurs?`}</p>
    <p>{`The preferred way to handle this is to listen to the payment-events kafka topic and react to PaymentTransactionCaptured-events.
To be able to consume kafka events, please see `}<a parentName="p" {...{
        "href": "#accessing-the-external-kafka-cluster"
      }}>{`Accessing the external Kafka Cluster`}</a></p>
    <p>{`When receiving a PaymentTransactionCaptured-Event after timing out, the client should clean up by crediting the transaction. Please see the `}<a parentName="p" {...{
        "href": "#refunding"
      }}>{`Refunding section`}</a>{`.
All other events can safely be ignored.`}</p>
    <p>{`Another solution is to start polling the transaction `}<a parentName="p" {...{
        "href": "https://petstore.swagger.io/?url=https://api.entur.io/api-docs/payment%3Fgroup%3Dpublic#/Payment%20API%20Public/findTransaction"
      }}>{`GET /sales/v1/payments/{paymentId}/transactions/{transactionId}`}</a>{`,
and check the status of the transaction, and when the status is captured clean up as stated above. We do not recommend this approach.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      